import React from 'react';

const GroupBoxes = ({ box }) => {
  return (
    <div style={{
      width: box.width,
      height: box.height,
      cursor: 'grab',
    }} />
  );
};

export default GroupBoxes;
