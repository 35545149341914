import React, { useCallback, useMemo, useState } from 'react'
import { calculateWidth, gridUnit, maxLeftBounds, maxTopBounds } from '../../constants/gridConfig'
import { useStore } from '../../store/store';
import { useDrop } from 'react-dnd';
import { ItemTypes, MASKS_TYPE, borderColumnColor, containerBackground, defaultItemValues, defaultZoom, dynamicZoom, pageBackground, viewerTypes } from '../../constants/constants';
import { initializeBoxContent, isADefaultBox, isADrawBox, isAOneRowBox, isASelectionBox, isATableBox, isATitleBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import BorderColumn from './BorderColumn';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { CustomDragLayer } from '../common/CustomDragLayer';
import { computeColumnWidth } from '../../utils/columns';
import Column from './Column';

const { confirm } = Modal;

const showConfirm = (replaceBox, setBoxIdToReplace) => {
  confirm({
    title: 'Êtes-vous sûr de remplacer cette box ?',
    icon: <ExclamationCircleFilled />,
    cancelText: "Annuler",
    okText: "Remplacer",
    onOk() {
      replaceBox();
      setBoxIdToReplace();
    },
    onCancel() {
      setBoxIdToReplace();
    },
  });
};

const selector = (id, fromViewer) => ({
  configuration,
  columns,
  maskEditing,
  masks,
  boxes: storeBoxes,
  moveMaskBox,
  moveBoxInfinite,
  dropBoxInColumn,
  landscape,
  fromPdf,
  containers,
  currentProposal,
  selectedBoxId,
}) => {
  const column = columns.find((c) => c.id === id);
  const container = containers.find((c) => c.columnsIds.includes(id));
  const mask = !column && (fromViewer || fromPdf || (maskEditing.length > 0
    && maskEditing.includes(id)))
    && masks.find((m) => m.id === id);
  const boxes = mask ? mask.boxes : storeBoxes.filter((box) => box.columnId === id);
  return {
    marginLeft: configuration.margins.left * gridUnit,
    marginRight: configuration.margins.right * gridUnit,
    column: columns.find((c) => c.id === id),
    titles: boxes.filter((box) => isATitleBox(box.type)),
    configuration,
    boxes,
    mask,
    moveBox: mask ? moveMaskBox : moveBoxInfinite,
    dropBoxInColumn,
    landscape,
    container: container,
    currentProposal,
    selectedBoxId
  }
};

const accept = [
  'newBox',
  ItemTypes.CONTAINER_COPIED,
  ItemTypes.IMG,
  ItemTypes.TEXT,
  ItemTypes.SHAPE,
  ItemTypes.LINE,
  ItemTypes.TABLE,
  ItemTypes.TITLE,
  ItemTypes.TITLE_2,
  ItemTypes.TITLE_3,
  ItemTypes.TITLE_4,
  ItemTypes.SUMMARY,
  ItemTypes.CHECKBOXS_VARIABLE,
  ItemTypes.SELECTOR_VARIABLE,
  ItemTypes.TEXT_VARIABLE,
  ItemTypes.IMG_VARIABLE,
  ItemTypes.TABLE_VARIABLE,
  ItemTypes.GROUP_BOXES
];

const ColumnWrapper = ({
  id,
  isContainerHovered,
  containerColumnsLength = 1,
  setIsResizingContainer,
  fromViewer,
  isLastColumn,
  isFirstColumn,
  isMiddleColumn
}) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const {
    configuration,
    titles,
    column,
    boxes,
    mask,
    marginLeft,
    marginRight,
    moveBox,
    dropBoxInColumn,
    landscape,
    container,
    currentProposal,
    selectedBoxId
  } = useStore(selector(id, fromViewer));
  const unlockProposal = useStore(({ configuration }) => configuration.unlockProposal)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')

  const size = mask ? 1 : column.size;
  const columnWidthPercentage = 100 * size;
  const [boxIdToReplace, setBoxIdToReplace] = useState();
  const [controlCol, setControlCol] = useState(false);
  const [controlActionHovered, setControlActionHovered] = useState(false);
  const [controlActionClicked, setControlActionClicked] = useState(false);
  const [deleteButtonHovered, setDeleteButtonHovered] = useState(false);
  const parentIdString = column ? `column-${column.id}` : `mask-${mask.id}`;
  const drawMode = !!(mask || column.drawMode);
  const columnWidth = computeColumnWidth({
    landscape,
    drawMode,
    marginLeft,
    marginRight,
    containerColumnsLength,
    size
  })

  const reinitializeBoxLeftAndTop = (box, { clientX, clientY }, zoomFactor) => {
    const bounds = document.getElementById(parentIdString).getBoundingClientRect();
    let left = (clientX - bounds.left - box.width / (2 / zoomFactor)) / zoomFactor;
    const defaultTop = clientY - bounds.top;
    let top = box.height
      ? (defaultTop - (box.height === 'auto' ? 0 : Number(box.height / (2 / zoomFactor)))) / zoomFactor
      : defaultTop / zoomFactor;

    if (box.left) {
      left = box.left;
    }
    if (isATableBox(box.type)) {
      left = configuration.margins.left * gridUnit
      box.width = calculateWidth(configuration.margins, 0, 0);
    } else if (box.type === ItemTypes.SUMMARY) {
      left = configuration.margins.left * gridUnit;
    }

    return {
      ...box,
      left: left < maxLeftBounds ? Math.round(maxLeftBounds) : Math.round(left),
      top: top < maxTopBounds ? Math.round(maxTopBounds) : Math.round(top),
    };
  }

  const handleBoxDropped = ({
    newBox,
    boxDrawMode,
    updateContainerHeight,
    boxIdToReplace }) => {
    dropBoxInColumn({
      id,
      newBox,
      drawMode: boxDrawMode,
      maskId: mask ? mask.id : undefined,
      updateContainerHeight,
      boxIdToReplace
    })
  }

  const createNewBox = (box, { clientX, clientY }) => {
    const boxBase = initializeBoxContent({
      box,
      configuration,
      titles,
      mask,
      column,
      parentId: id
    });
    if (drawMode) {
      const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;

      const newBox = reinitializeBoxLeftAndTop(boxBase, { clientX, clientY }, zoomFactor);
      handleBoxDropped({ newBox, boxDrawMode: true })
    } else {
      const newBox = reinitializeBoxWidthAndHeight({ box: boxBase, columnWidth, onCreate: true, header, footer });
      const boxDrawMode = isADrawBox(box.type);
      if (boxes.length === 0) {
        handleBoxDropped({
          newBox,
          boxDrawMode,
          updateContainerHeight: boxDrawMode,
        })
      }
      else {
        setBoxIdToReplace(boxes[0].id)
        showConfirm(() => {
          handleBoxDropped({
            newBox,
            boxDrawMode,
            updateContainerHeight: boxDrawMode,
            boxIdToReplace: boxes[0].id
          })
        },
          setBoxIdToReplace)
      }
    }
  }

  const onDrop = ({ box, monitor }) => {
    if (!monitor.isOver({ shallow: true })) return;

    if (monitor.getItemType() === 'newBox') {
      const clientOffset = monitor.getClientOffset();
      createNewBox(box, { clientX: clientOffset.x, clientY: clientOffset.y })
    }
    else {
      if (drawMode) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const zoomFactor = (configuration.zoom || dynamicZoom() || defaultZoom) / 100;

        let left = Math.round((box.left + (delta.x / zoomFactor)));
        let top = Math.round((box.top + (delta.y / zoomFactor)));
        moveBox(left, top, box.id, id);
      }
    }
  }

  const boxCanDrop = useCallback((_, monitor) => {
    const box = monitor.getItem();
    if (box.type === ItemTypes.GROUP_BOXES && !drawMode) return false;
    if (container?.editable === false && currentProposal.id !== null) return false;
    if (currentProposal.id === null && container?.from_linked_section) return false;
    // Can't move box from parent to another one
    if (box.parentId && box.parentId !== id) {
      return false
    }
    // If column less than full size and dragged box require full size
    if (!drawMode && isAOneRowBox(box.type) && size !== 1) {
      return false;
    }
    // If column is in drawMode and dragged box is a drawMode or is only for default columns
    if ((isADrawBox(box.type) || isADefaultBox(box.type)) && drawMode) {
      return false
    }
    // If dropped box is a mask
    if (boxes.length === 1 && boxes[0].type === ItemTypes.MASK) {
      return false
    }
    if (boxes.length === 1 && isASelectionBox(boxes[0].type)) {
      return false
    }
    if (!isTemplate && !unlockProposal) {
      return false
    }
    return true
  }, [container?.editable, currentProposal.id, id, drawMode, size, boxes, isTemplate, unlockProposal])

  const dropCommonProps = useMemo(() => ({
    accept,
    collect: (monitor) => ({
      boxIsOver: monitor.isOver({ shallow: true }) && (!drawMode || monitor.getItemType() === 'newBox'),
      canDrop: monitor.canDrop(),
      isDragging: monitor.getItem() !== null && monitor.getItemType() === 'newBox'
    }),
  }), [drawMode])

  const [{ boxIsOver, canDrop, isDragging }, dropColumn] = useDrop({
    ...dropCommonProps,
    canDrop: boxCanDrop,
    drop: (box, monitor) => onDrop({ box, monitor }),
  });

  const willBoxBeReplaced = (boxIdToReplace && boxes.length === 1 && boxes[0].id === boxIdToReplace)

  const boxPosition = useCallback(() => {
    const centered = {
      justifyContent: "center",
      alignItems: "center",
    }

    if (boxes.length !== 1) {
      return centered
    }
    const box = boxes.find((box) => boxes[0].id === box.id);
    return {
      justifyContent: box.type !== ItemTypes.LINE_BREAK ? box.positions?.horizontal : "flex-start",
      alignItems: box.positions?.vertical
    }

  }, [boxes])

  const addTextBoxOnDoubleClick =
    (e) => {
      if (!(isTemplate && !unlockProposal) || fromViewer === viewerTypes.PAGENUMBER || fromViewer === viewerTypes.DMZ) return;
      if ((boxes.length > 0 && (
        !drawMode ||
        boxes[0].type === ItemTypes.MASK ||
        boxes[0].type === ItemTypes.SECTION ||
        boxes.some((b) => selectedBoxId && b.id === selectedBoxId)))
        || (!container?.editable &&
          container?.hasOwnProperty("editable") &&
          currentProposal.id !== null)) return;

      const { clientX, clientY } = e;
      const newBox = {
        ...defaultItemValues.text,
        type: 'text',
      };
      createNewBox(newBox, { clientX, clientY })
    };

  const columnStyle = useMemo(() => {
    const baseStyle = {
      width: `${columnWidthPercentage}%`,
      maxWidth: `${columnWidthPercentage}%`,
      display: "flex",
      position: 'relative',
    }
    if (drawMode) return baseStyle
    return { ...baseStyle, ...boxPosition() }
  }, [boxPosition, columnWidthPercentage, drawMode])

  const borderColumnProps = useMemo(() => ({
    willBoxBeReplaced,
    containerColumnsLength,
    columnId: id,
    dropCommonProps,
    isContainerHovered,
    boxCanDropInColumn: canDrop,
    hasAOneRowBox: boxes.length === 1 && isAOneRowBox(boxes[0].type)
  }), [containerColumnsLength, dropCommonProps, id, isContainerHovered, willBoxBeReplaced, canDrop, boxes])

  return <div
    ref={dropColumn}
    id={parentIdString}
    style={columnStyle}
    data-role={pageBackground}
    data-parent={containerBackground}
    onDoubleClick={addTextBoxOnDoubleClick}
  >

    {!drawMode && <BorderColumn
      {...borderColumnProps}
      position='left'
      fromViewer={fromViewer}
    />}
    <Column
      id={id}
      drawMode={drawMode}
      canDrop={canDrop}
      isDragging={isDragging}
      columnWidth={columnWidth}
      configuration={configuration}
      boxes={boxes}
      mask={mask}
      fromViewer={fromViewer}
      columnMaskId={column?.maskId}
      isResizable={
        fromViewer !== viewerTypes.PAGENUMBER &&
        fromViewer !== viewerTypes.DMZ &&
        !landscape &&
        drawMode &&
        (!mask || mask.id === MASKS_TYPE.HEADER.id || mask.id === MASKS_TYPE.FOOTER.id) &&
        !column?.maskId &&
        !column?.isOldPage &&
        !boxes.some((b) => isASelectionBox(b.type))
      }
      backgroundColor={mask ? mask?.backgroundColor : column?.backgroundColor}
      setIsResizingContainer={setIsResizingContainer}
    />
    {!drawMode && <BorderColumn
      fromViewer={fromViewer}
      {...borderColumnProps}
      position='right'
      setControlCol={setControlCol}
      controlCol={controlCol}
      setControlActionClicked={setControlActionClicked}
      setControlActionHovered={setControlActionHovered}
      controlActionClicked={controlActionClicked}
      setDeleteButtonHovered={setDeleteButtonHovered}
      deleteButtonHovered={deleteButtonHovered}
      controlActionHovered={controlActionHovered}
      isLastColumn={isLastColumn}
      isFirstColumn={isFirstColumn}
      isMiddleColumn={isMiddleColumn}
    />}
    <div
      style={{
        position: 'absolute',
        backgroundColor: deleteButtonHovered ? "red" : borderColumnColor,
        opacity: 0.3,
        width: '100%',
        height: '100%',
        zIndex: 2,
        scale: `${(boxIsOver && canDrop) || controlCol === column?.id ? '1' : '0'} 1`,
        left: 0,
        transformOrigin: 'left'
      }}
    />
    <CustomDragLayer id={id} drawMode={drawMode} zoom={configuration.zoom} />
  </div>
}

export default ColumnWrapper
