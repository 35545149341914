import { Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { navbarButtonStyle } from "../../utils/styles";

export const NavbarButton = ({ tooltipContent, onClick, disabled = false, hidden, icon, children, style, buttonStyle, className }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    if (disabled) setTooltipVisible(false)
  }, [disabled])

  const buttonDisplay = (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
      opacity: disabled ? 0.3 : 1,
      ...style
    }}>
      {icon}
      {!icon && children}
    </div>
  )

  return (
    <Tooltip
      open={tooltipVisible}
      placement='bottom'
      mouseLeaveDelay='0'
      title={tooltipContent}
    >
      <Button
        disabled={disabled}
        hidden={hidden}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        style={{
          ...navbarButtonStyle,
          ...buttonStyle
        }}
        className={className}
        onMouseDown={(e) => e.preventDefault()}
        onClick={disabled ? null : onClick}
      >
        {buttonDisplay}
      </Button>
    </Tooltip>
  )
}
