import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Select } from 'antd'
import { useStore } from '../../store/store';
import './SelectorVariable.less'
import ReactDOM from 'react-dom';
import NavbarSelector from '../Layout/NavbarSelector';
import { CaretDownOutlined } from '@ant-design/icons';
import { Separator } from '../Layout/Navbar';
import { useTranslation } from 'react-i18next';
import { isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { ContentState, Editor, EditorState, Modifier } from 'draft-js';
import FloatyStylingWrapper from '../Table/FloatyStylingWrapper';
import { useMenuPosition, useZIndexContainer } from '../../hooks';
import HideInFormSwitcher from './HideInFormSwitcher';
import { decorator } from '../RichEditor/utils';
import { viewerTypes } from '../../constants/constants';
import { mergeConfigurationSelectorStyles } from '../../utils/styles';

const selector = (box) =>
  ({ updateBox, selectedBoxId, fromPdf, boxVariableSelectedId, configuration, selectedContainer, tmp }) => {
    return {
      isSelected: box.isSelected ?? selectedBoxId === box.id,
      updateBox: box?.updateBox ?? updateBox,
      fromPdf,
      boxVariableSelectedId,
      configuration,
      selectorStyle: mergeConfigurationSelectorStyles(configuration, tmp).find((ts) => ts.id === box?.selectorStyleId),
      selectedContainerId: selectedContainer?.id
    };
  };

const { Option } = Select;

const StylingComponent = ({ updateBox, box, configuration, selectorStyle, hasOptionsAfter = true }) => {

  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <HideInFormSwitcher boxId={box.id} />
      <NavbarSelector
        tooltipContent={t('selector-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(box.id, (box) => {
            box.selectorStyleId = configuration.selectorStyles.find((ts) => ts.name === e).id;
          });
        }}
        dropdownStyle={{ zIndex: 9999 }}
        selectedValue={selectorStyle?.name}
        options={configuration.selectorStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      {hasOptionsAfter && <Separator />}
    </div>
  )
}

const SelectorVariable = ({ box }) => {
  const {
    options,
    maxNumberOfAnwser
  } = box
  const ref = useRef()
  const [focus, setFocus] = useState(false)
  const menuPosition = useMenuPosition(ref)

  const { updateLeftShownStatus, overlayProps } = useOverlay();
  const shownStatus = overlayProps?.left?.shownStatus
  const {
    updateBox,
    isSelected,
    fromPdf,
    boxVariableSelectedId,
    selectorStyle,
    configuration,
    selectedContainerId
  } = useStore(selector(box));
  const selectorRef = useRef()
  const hasCheckedMaxNumberOfAnswers = options.filter((c) => c.selected).length === maxNumberOfAnwser

  const selectedOptions = useMemo(() => {
    return options.filter((o) => o.selected)
  }, [options])

  // Focus on one click
  useEffect(() => {
    if (isSelected && boxVariableSelectedId !== box.id) {
      selectorRef.current.focus();
    }
  }, [isSelected, boxVariableSelectedId, box.id])

  const updateSelector = (optionIds = []) => updateBox(box.id, (box) => {
    box.options = box.options.map((option) => optionIds.includes(option.id) ? {
      ...option,
      selected: true
    } : {
      ...option,
      selected: false
    })
  })
  const removeOption = () => updateBox(box.id, (box) => {
    box.options = box.options.map((option) => ({
      ...option,
      selected: false
    }))
  })

  function combineIntoSingleBlock(editorStates) {
    if (editorStates.length === 0) {
      return EditorState.createEmpty();
    }

    // Extract all blocks from the editor states
    let combinedContent = ContentState.createFromText('');
    editorStates.forEach((editorState) => {
      const content = editorState.getCurrentContent();
      const fragment = ContentState.createFromBlockArray(content.getBlockMap().toArray()).getBlockMap();
      combinedContent = Modifier.replaceWithFragment(
        combinedContent,
        combinedContent.getSelectionAfter(),
        fragment
      );
    }, []);

    return EditorState.createWithContent(combinedContent, decorator);
  }


  const renderSelectedOptions = (options) => {
    const separator = selectorStyle?.separator === "\n" ? selectorStyle?.separator : ` ${selectorStyle?.separator} `

    return <Editor
      readOnly
      editorState={combineIntoSingleBlock(options.map(({ label }, idx) => {
        return idx === options.length - 1 ? [label] : [label, EditorState.createWithContent(ContentState.createFromText(separator))]
      }).flat())} />
  }

  const getStyle = () => {
    return {
      width: "100%",
      minHeight: 16,
      backgroundColor: selectorStyle?.backgroundColor?.style,
      borderRadius: selectorStyle?.borderRadius,
      padding: `${selectorStyle?.paddingTopBottom}px ${selectorStyle?.paddingLeftRight}px`,
      border: `${selectorStyle?.bordersSize}px solid ${selectorStyle?.borderColor?.style}`,
      color: selectorStyle?.color?.style,
      fontSize: selectorStyle?.fontSize,
      display: 'flex',
      justifyContent: selectorStyle?.position,
      fontFamily: selectorStyle?.fontFamily,
      fontWeight: `${selectorStyle?.fontWeight}`,
      textDecoration: `${selectorStyle?.textDecoration}`,
      fontStyle: `${selectorStyle?.fontStyle}`,
    }
  }

  const hasPriority = !!selectedContainerId && focus

  useZIndexContainer(selectedContainerId, hasPriority)

  return (
    <div style={{
      position: 'relative',
      width: box.width,
      display: 'flex',
      alignItems: 'center',
      zIndex: 1000,
    }}
      ref={ref}
      className='selector-variable'
    >
      {!isSelected && <div style={getStyle()} onDoubleClick={(event) => {
        if (isAVariableBox(box?.type) && !box.isBoxSimulated) {
          updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
        }
        event.stopPropagation()
      }}>
        <span style={{
          wordWrap: 'break-word',
          whiteSpace: 'break-spaces'
        }}>
          {selectedOptions.length < 1 && !fromPdf &&
            <div style={{
              color: 'grey'
            }}>
              Sélectionnez une réponse
            </div>}
          {renderSelectedOptions(selectedOptions)}
        </span>
      </div>}
      {isSelected && <Select
        getPopupContainer={triggerNode => triggerNode.parentElement}
        dropdownStyle={{ zIndex: 9999 }}
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type) && !box.isBoxSimulated) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
        onChange={updateSelector}
        ref={selectorRef}
        showAction={['focus']}
        value={selectedOptions.map((o) => o.id)}
        allowClear
        onClear={removeOption}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        mode={maxNumberOfAnwser > 1 ? 'multiple' : undefined}
        search={false}
        style={{
          width: '100%',
          minHeight: 16
        }}
      >
        {options.map((option) => {
          return (
            <Option
              key={option.id}
              value={option.id}
              disabled={hasCheckedMaxNumberOfAnswers && !option.selected && maxNumberOfAnwser !== 1}
            >
              <Editor
                readOnly
                editorState={option.label} />
            </Option>
          );
        })}
      </Select>}
      {isSelected && !box.isBoxSimulated && (shownStatus !== "variables" || box.fromViewer === viewerTypes.VARIABLES) &&
        ReactDOM.createPortal(
          <StylingComponent updateBox={updateBox} box={box} configuration={configuration} selectorStyle={selectorStyle} />,
          document.getElementById('ComponentPortal')
        )}
      {isSelected && box.isBoxSimulated && box.withMenu &&
        ReactDOM.createPortal(
          <FloatyStylingWrapper menuPosition={menuPosition} >
            <StylingComponent updateBox={updateBox} box={box} configuration={configuration} selectorStyle={selectorStyle} hasOptionsAfter={false} />
          </FloatyStylingWrapper>,
          document.getElementById('ComponentPortal')
        )}
    </div>
  )
}

export default SelectorVariable
