import { Button } from 'antd'
import React, { useCallback, useRef } from 'react'
import ReactDOM from 'react-dom';
import { useStore } from '../../store/store';
import { getBackgroundBorderStyle, iconButtonStyle, mergeConfigurationCheckboxStyles, navbarButtonStyle } from '../../utils/styles';
import { Separator } from '../Layout/Navbar';
import { NavbarListButton } from '../Layout/NavbarListButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { dispostionButtons, ItemTypes, viewerTypes } from '../../constants/constants';
import NavbarSelector from '../Layout/NavbarSelector';
import { CaretDownOutlined } from '@ant-design/icons';
import CheckboxCustom from './CheckboxCustom';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { isAVariableBox } from '../../utils/boxes';
import { Editor } from 'draft-js';
import { useMenuPosition } from '../../hooks';
import FloatyStylingWrapper from '../Table/FloatyStylingWrapper';
import HideInFormSwitcher from './HideInFormSwitcher';

const selector = (box) =>
  ({ updateBox, selectedBoxId, configuration, tmp }) => {

    return {
      isSelected: box.isSelected ?? selectedBoxId === box.id,
      updateBox: box.updateBox ?? updateBox,
      configuration,
      checkboxStyle: mergeConfigurationCheckboxStyles(configuration, tmp).find((ts) => ts.id === box?.checkboxStyleId)
    };
  };

const StylingComponent = ({ updateBox, box, configuration, checkboxStyle, hasOptionsAfter = true }) => {

  const { t } = useTranslation();

  const changeDisposition = (disposition) => {
    updateBox(box.id, (box) => {
      box.disposition = disposition
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {box.type !== ItemTypes.QUESTION && <HideInFormSwitcher boxId={box.id} />}
      <NavbarListButton
        title={t('disposition')}
        icon={< FontAwesomeIcon icon={icon({ name: 'grid-dividers', style: 'light' })} style={iconButtonStyle()} />}
      >
        {Object.keys(dispostionButtons).map((buttonName) => {
          return (
            <Button
              key={`${buttonName}`}
              style={{
                ...navbarButtonStyle,
                marginBottom: '1px',
              }}
              disabled={false}
              icon={dispostionButtons[buttonName].icon}
              onClick={() => changeDisposition(dispostionButtons[buttonName].disposition)}
            />
          );
        })}
      </NavbarListButton>
      <NavbarSelector
        tooltipContent={t('checkbox-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(box.id, (box) => {
            box.checkboxStyleId = configuration.checkboxStyles.find((ts) => ts.name === e).id;
          });
        }}
        selectedValue={checkboxStyle?.name}
        dropdownStyle={{ zIndex: 9999 }}
        options={configuration.checkboxStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      {hasOptionsAfter && <Separator />}
    </div>
  )
}

const CheckboxsVariable = ({ box }) => {
  const {
    checkboxs,
    maxNumberOfAnwser
  } = box
  const { updateLeftShownStatus, overlayProps } = useOverlay();
  const shownStatus = overlayProps?.left?.shownStatus
  const hasCheckedMaxNumberOfAnswers = checkboxs.filter((c) => c.checked).length === maxNumberOfAnwser
  const ref = useRef()
  const menuPosition = useMenuPosition(ref)

  const {
    updateBox,
    isSelected,
    configuration,
    checkboxStyle
  } = useStore(selector(box));

  const getStyleOnCheckbox = useCallback(() => {
    return {
      width: checkboxStyle?.box?.width,
      height: checkboxStyle?.box?.height,
      backgroundColor: checkboxStyle?.box?.backgroundColor.style,
      border: `${checkboxStyle?.borders?.size}px solid ${checkboxStyle?.borders?.color?.style}`,
      borderRadius: `${checkboxStyle?.borders?.radius}px`,
      color: checkboxStyle?.check?.color?.style,
      fontSize: `${checkboxStyle?.check?.height}px`,
      fontWeight: `${checkboxStyle?.check?.weight}`,
    }
  }, [checkboxStyle])

  const renderCheckbox = ({ checked, label }, idx) => {
    return <div key={idx} style={{
      display: 'flex',
      columnGap: 8,
      overflow: 'hidden',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <CheckboxCustom
        label={<Editor
          readOnly
          editorState={label} />} getStyleOnCheckbox={getStyleOnCheckbox}
        checked={checked}
        disabled={hasCheckedMaxNumberOfAnswers && !checked && maxNumberOfAnwser !== 1}
        box={box}
        idx={idx}
        maxNumberOfAnwser={maxNumberOfAnwser}
      />
    </div>
  }

  const getStyle = () => {
    const keys = [
      'textDecoration',
      'fontStyle'
    ]

    const style = { ...checkboxStyle ? checkboxStyle : configuration.checkboxStyles[0] }
    keys.forEach((key) => {
      if (style[key]) {
        delete style[key]
      }
    })

    let gridTemplateColumns = "";
    let dispo;
    if (box.disposition) dispo = box.disposition
    else dispo = style.disposition
    for (let i = 0; i < dispo; i++) {
      gridTemplateColumns += "1fr "
    }
    return {
      display: "grid",
      gridTemplateColumns,
      ...getBackgroundBorderStyle(style.backgroundColor, style.borders, style),
      color: style.color.style,
    }
  }

  return (
    <>
      <div style={{
        ...getStyle(),
        width: box.width,
      }}
        ref={ref}
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type) && !box.isBoxSimulated) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
      >
        {checkboxs?.map((c, idx) => renderCheckbox(c, idx))}
      </div>
      {isSelected && !box.isBoxSimulated && (shownStatus !== "variables" || box.fromViewer === viewerTypes.VARIABLES) &&
        ReactDOM.createPortal(
          <StylingComponent updateBox={updateBox} box={box} configuration={configuration} checkboxStyle={checkboxStyle} />,
          document.getElementById('ComponentPortal')
        )}
      {isSelected && box.isBoxSimulated && box.withMenu &&
        ReactDOM.createPortal(
          <FloatyStylingWrapper menuPosition={menuPosition} >
            <StylingComponent updateBox={updateBox} box={box} configuration={configuration} checkboxStyle={checkboxStyle} hasOptionsAfter={false} />
          </FloatyStylingWrapper>,
          document.getElementById('ComponentPortal')
        )}
    </>
  )
}

export default CheckboxsVariable
