
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button,
  Input,
  Table,
  Tooltip,
  Popconfirm,
  Form,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import { useCallback, useState } from 'react';
import { useStore } from '../../../store/store';
import ColorSelector from '../../common/ColorSelector';
import './tableConfig.css';
import { ItemTypes, defaultCheckboxConfiguration, dispostionButtons, styleCheckbox } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { maxFontSize, minFontSize } from '../../../constants/gridConfig';
import { BlockTextColor } from '../../common/TitleConfiguration';
import { getBackgroundBorderStyle, iconButtonStyle, mergeConfigurationCheckboxStyles, navbarButtonStyle } from '../../../utils/styles';
import { NavbarListButton } from '../../Layout/NavbarListButton';
import CheckboxCustom from '../../common/CheckboxCustom';
import FontPicker from '../../common/FontPicker';

const selector = ({
  templates,
  configuration,
  setSelectedStyle,
  currentTemplate,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
  tmp
}) => ({
  checkboxStyles: templates.filter((t) => t.pattern === 'style'),
  templates,
  configuration,
  setSelectedStyle,
  templatable: currentTemplate.templatable,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
  tmp
});

export const TabsCheckbox = () => {
  const { configuration, setConfiguration, tmp } = useStore(selector);
  const [selectedStyle, setSelectedStyle] = useState(mergeConfigurationCheckboxStyles(configuration, tmp)[0]);
  const [newStyleName, setNewStyleName] = useState('');
  const [isEditingStyleName, setIsEditingStyleName] = useState(false);
  const { t } = useTranslation();
  let styleList = [...mergeConfigurationCheckboxStyles(configuration, tmp)];
  const styleData = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: (18 + 16) * 3 + 16,
    },
    {
      title: 'Style',
      dataIndex: 'style',
      key: 'style',
      onCell: (element, rowIndex) => {
        return {
          key: `style-config-${rowIndex}`,
          onClick: (event) => {
            handleSelectedStyle(element);
          },
        };
      },
    }
  ];

  const handleRemoveStyle = (idToRemove) => {
    const newCheckboxStyles = configuration.checkboxStyles.filter(style => style.id !== idToRemove);
    setConfiguration({
      ...configuration.checkboxStyles,
      checkboxStyles: newCheckboxStyles,
    }, (draft) => {
      draft.boxes.map((b) => {
        if ((b.type === ItemTypes.CHECKBOXS_VARIABLE || b.type === ItemTypes.QUESTION) && b.checkboxStyleId === idToRemove) {
          b.checkboxStyleId = draft.configuration.checkboxStyles[0].id
        }
        return b
      })
    });
  };

  const handleRenameStyle = (ID, event) => {
    let styleToRename = {
      ...configuration.checkboxStyles.find((element) => element.id === ID),
      name: event.target.value,
    };
    setConfiguration({
      checkboxStyles: [
        ...configuration.checkboxStyles.map((tableStyle) => {
          if (tableStyle.id === ID) {
            return styleToRename;
          }
          return tableStyle;
        }),
      ],
    });
    setIsEditingStyleName(null);
    setEditedName('');
    setSelectedStyle(styleToRename);
  };

  const handleCopyStyle = (copiedId) => {
    const copiedStyle = configuration.checkboxStyles.find((s) => s.id === copiedId);
    let newStyle = {
      ...copiedStyle,
      id: configuration.checkboxStylesIdCount + 1,
      name: `${copiedStyle.name} - Copie`,
    };
    let checkboxStyles = [
      ...configuration.checkboxStyles,
      newStyle,
    ];

    setConfiguration({
      ...configuration,
      checkboxStyles,
      checkboxStylesIdCount: configuration.checkboxStylesIdCount + 1,
    });
  };

  const [editedName, setEditedName] = useState('');
  const dataSourceMapped = [];

  const iconStyle = {
    margin: '.5rem',
    fontSize: '1.5em',
  }
  mergeConfigurationCheckboxStyles(configuration, tmp).forEach((element) => {
    const cantEdit = element.id === 0 || element.fromTmp === true;
    const checkboxStyleLocked = element.fromTmp === true;
    dataSourceMapped.push({
      key: `tableStyle-${element.id}`,
      id: element.id,
      style:
        isEditingStyleName === element.id ? (
          <Input
            value={editedName}
            id='styleNameInput2'
            style={{ width: '60%' }}
            onChange={(e) => setEditedName(e.target.value)}
            onPressEnter={(e) => {
              if (element.id !== 0 && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
            onBlur={(e) => {
              if (!cantEdit && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
          />
        ) : (
          element.name
        ),
      actions: (
        <div>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Renommer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'edit', style: 'light' })}
              style={{
                ...iconStyle,
                color: cantEdit ? 'lightgray' : 'black',
              }}
              onClick={cantEdit ? null : (e) => {
                setIsEditingStyleName(element.id);
                setEditedName(element.name);
              }}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Dupliquer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'copy', style: 'light' })}
              style={{
                ...iconStyle,
                color: checkboxStyleLocked ? 'lightgray' : 'black',
              }}
              onClick={checkboxStyleLocked ? null : (e) => handleCopyStyle(element.id)}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Supprimer'}
          >
            {cantEdit ? (
              <FontAwesomeIcon
                icon={icon({ name: 'trash', style: 'light' })}
                style={{
                  ...iconStyle,
                  color: 'lightgray'
                }}
              />
            ) : (
              <Popconfirm
                title='Voulez vous vraiment supprimer ce style ?'
                onConfirm={() => { handleRemoveStyle(element.id); }}
                okText='Oui'
                cancelText='Non'
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'trash', style: 'light' })}
                  style={{
                    ...iconStyle,
                    color: 'red'
                  }}
                />
              </Popconfirm>
            )}
          </Tooltip>
        </div>
      ),
    });
  });

  const [isAddTableStyleLoading, setIsAddTableStyleLoading] = useState(false);

  const handleSelectedStyle = (e) => {
    mergeConfigurationCheckboxStyles(configuration, tmp).forEach((element) => {
      if (element.id === e.id) {
        setSelectedStyle(element);
      }
    });
    return selectedStyle;
  };

  const loadingAnimation = () => {
    setIsAddTableStyleLoading(true);
    setTimeout(() => {
      setIsAddTableStyleLoading(false);
    }, 1000);
  };

  // const handleChange
  const handleSaveChanges = useCallback((updatedTableStyle) => {
    const updatedTableStyleIndex = configuration.checkboxStyles.findIndex(
      (element) => element.id === updatedTableStyle.id
    );
    let newCheckboxStyles = [...configuration.checkboxStyles];
    newCheckboxStyles[updatedTableStyleIndex] = updatedTableStyle;
    setSelectedStyle(updatedTableStyle);
    setConfiguration({
      ...configuration.checkboxStyles,
      checkboxStyles: newCheckboxStyles
    });
  }, [configuration?.checkboxStyles, setConfiguration]);

  const handleAddTableStyle = (e) => {
    loadingAnimation();
    if (newStyleName.length > 0) {
      const newStyle = {
        ...defaultCheckboxConfiguration,
        id: configuration.checkboxStylesIdCount,
        name: newStyleName,
      };
      let checkboxStyles = [
        ...configuration.checkboxStyles,
        newStyle,
      ];
      setConfiguration({
        ...configuration,
        checkboxStyles,
        checkboxStylesIdCount: configuration.checkboxStylesIdCount + 1,
      });
      setSelectedStyle({ ...newStyle });
      setNewStyleName('');
    } else {
      alert('Un nom de template est obligatoire');
    }
  };

  const handleChangeFontSize = useCallback(() => (value) => {

    handleSaveChanges({
      ...selectedStyle,
      fontSize: value,
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeFontWeight = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontWeight: value.target.checked ? 'bold' : '',
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeFontStyle = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontStyle: value.target.checked ? 'italic' : '',
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeFontDecoration = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      textDecoration: value.target.checked ? 'underline' : '',
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeStyleBox = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      styleBox: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBackgroundColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      backgroundColor: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBoxBackgroundColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      box: {
        ...selectedStyle.box,
        backgroundColor: { "label": value, "style": configuration.colors.find((color) => color.label === value).style }
      }
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeSizeBox = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      box: {
        ...selectedStyle.box,
        width: value,
        height: value,
      }
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeDirectionLabel = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      box: {
        ...selectedStyle.box,
        direction: value.target.checked ? "rtl" : "ltr",
      }
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeBorderColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      borders: {
        ...selectedStyle.borders,
        ...{ color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style } }
      },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBorderSize = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      borders: {
        ...selectedStyle.borders,
        size: value,
      },
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeBorderRadius = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      borders: {
        ...selectedStyle.borders,
        radius: value,
      },
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeColorCheck = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      check: {
        ...selectedStyle.check,
        ...{ color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style } }
      },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeSizeCheck = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      check: {
        ...selectedStyle.check,
        width: value,
        height: value,
      },
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeCheckWeight = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      check: {
        ...selectedStyle.check,
        weight: value,
      },
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeBorderPlace = useCallback(() => (value) => {
    if (value === 'all') {
      handleSaveChanges({
        ...selectedStyle,
        borders: {
          ...selectedStyle.borders,
          top: true,
          bottom: true,
          left: true,
          right: true,
        },
      });
    } else {
      handleSaveChanges({
        ...selectedStyle,
        borders: {
          ...selectedStyle.borders,
          [value]: !selectedStyle.borders[value],
        },
      });
    }

  }, [handleSaveChanges, selectedStyle]);

  const handleChangeDisposition = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      disposition: value,
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeMarginLabel = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      label: {
        ...selectedStyle.label,
        margin: value,
      }
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeFontFamily = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontFamily: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const changeHandler = useCallback(() => ({
    fontSize: handleChangeFontSize(),
    fontWeight: handleChangeFontWeight(),
    fontStyle: handleChangeFontStyle(),
    fontFamily: handleChangeFontFamily(),
    textDecoration: handleChangeFontDecoration(),
    color: handleChangeColor(),
    backgroundColor: handleChangeBackgroundColor(),
    borderColors: handleChangeBorderColor(),
    borderPlace: handleChangeBorderPlace(),
    sizeBox: handleChangeSizeBox(),
    styleBox: handleChangeStyleBox(),
    colorCheck: handleChangeColorCheck(),
    sizeCheck: handleChangeSizeCheck(),
    borderSize: handleChangeBorderSize(),
    borderRadius: handleChangeBorderRadius(),
    disposition: handleChangeDisposition(),
    marginLabel: handleChangeMarginLabel(),
    directionLabel: handleChangeDirectionLabel(),
    checkWeight: handleChangeCheckWeight(),
    changeBoxBackgroundColor: handleChangeBoxBackgroundColor()
  }), [
    handleChangeFontSize,
    handleChangeFontWeight,
    handleChangeFontDecoration,
    handleChangeFontStyle,
    handleChangeColor,
    handleChangeBackgroundColor,
    handleChangeBorderColor,
    handleChangeBorderPlace,
    handleChangeSizeBox,
    handleChangeStyleBox,
    handleChangeColorCheck,
    handleChangeSizeCheck,
    handleChangeBorderSize,
    handleChangeBorderRadius,
    handleChangeDisposition,
    handleChangeMarginLabel,
    handleChangeDirectionLabel,
    handleChangeCheckWeight,
    handleChangeFontFamily,
    handleChangeBoxBackgroundColor
  ])

  const getStyleOnCheckbox = useCallback(() => {
    return {
      width: selectedStyle?.box?.width,
      height: selectedStyle?.box?.height,
      backgroundColor: selectedStyle?.box?.backgroundColor.style,
      border: `${selectedStyle?.borders?.size}px solid ${selectedStyle?.borders?.color?.style}`,
      borderRadius: `${selectedStyle?.borders?.radius}px`,
      color: selectedStyle?.check?.color?.style,
      fontSize: `${selectedStyle?.check?.height}px`,
      fontWeight: `${selectedStyle?.check?.weight}`,
    }
  }, [selectedStyle])

  const getStyle = () => {
    const keys = [
      'textDecoration',
      'fontStyle'
    ]

    const style = { ...selectedStyle }
    keys.forEach((key) => {
      if (style[key]) {
        delete style[key]
      }
    })

    let gridTemplateColumns = "";
    for (let i = 0; i < style.disposition; i++) {
      gridTemplateColumns += "1fr "
    }
    return {
      display: "grid",
      gridTemplateColumns,
      ...getBackgroundBorderStyle(style.backgroundColor, style.borders, style),
      color: style?.color?.style,
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div style={{ textAlign: 'center', width: "40%" }}>
        <h5>Liste des styles de cases à cocher</h5>
        <div
          style={{
            margin: '2rem 0',
            padding: '0 2rem',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Input
            placeholder='Nom du nouveau Style'
            value={newStyleName}
            onChange={(e) => {
              e.preventDefault();
              setNewStyleName(e.target.value);
            }}
            onPressEnter={handleAddTableStyle}
          />
          <Button
            type='primary'
            htmlType='submit'
            loading={isAddTableStyleLoading}
            style={{ marginLeft: '.5rem' }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={handleAddTableStyle}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'plus', style: 'light' })}
              style={{ fontSize: '1.5em' }}
            />
          </Button>
        </div>
        <Table
          columns={styleData}
          dataSource={dataSourceMapped}
          rowClassName={(record, _rowIndex) => { return record.style === selectedStyle.name ? 'tableSelectedRow' : '' }}
          pagination={false}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "60%", margin: 20 }}>
        <div style={{
          ...getStyle(),
          marginBottom: 20
        }}>
          <CheckboxCustom selectedStyleId={selectedStyle?.id} getStyleOnCheckbox={getStyleOnCheckbox} label="Exemple" checked={true} />
          <CheckboxCustom selectedStyleId={selectedStyle?.id} getStyleOnCheckbox={getStyleOnCheckbox} label="Exemple" checked={true} />
          <CheckboxCustom selectedStyleId={selectedStyle?.id} getStyleOnCheckbox={getStyleOnCheckbox} label="Exemple" checked={true} />
          <CheckboxCustom selectedStyleId={selectedStyle?.id} getStyleOnCheckbox={getStyleOnCheckbox} label="Exemple" checked={true} />
          <CheckboxCustom selectedStyleId={selectedStyle?.id} getStyleOnCheckbox={getStyleOnCheckbox} label="Exemple" checked={true} />
        </div>
        <div style={{ width: "100%" }} key={selectedStyle?.id}>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style du label
            </legend>
            <Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Police des labels"
                name='fontFamily'
              >
                <FontPicker
                  disabled={selectedStyle.id === 0 || selectedStyle.fromTmp}
                  forcedValue={selectedStyle.id === 0 ? configuration.fontFamily : selectedStyle?.fontFamily}
                  onChange={(e) => changeHandler().fontFamily(e)} />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label={t('font-size')}
                name={`fontSize-checkbox-variables-${selectedStyle?.id}`}
              >
                <InputNumber
                  min={minFontSize}
                  max={maxFontSize}
                  disabled={selectedStyle.id === 0 || selectedStyle.fromTmp}
                  defaultValue={selectedStyle.id === 0 ? configuration.fontSize : selectedStyle?.fontSize}
                  onChange={(e) => changeHandler().fontSize(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item style={{ display: 'inline-block', margin: '0 8px' }}>
                <Form.Item label={t('bold')} style={{ display: 'inline-block', margin: '0 8px' }}>
                  <Checkbox
                    key='bold'
                    style={{ fontWeight: 'bold' }}
                    checked={selectedStyle?.fontWeight !== ''}
                    disabled={selectedStyle?.fromTmp}
                    onChange={(e) => changeHandler().fontWeight(e)}
                  />
                </Form.Item>
                <Form.Item label={t('italic')} style={{ display: 'inline-block', margin: '0 8px' }}>
                  <Checkbox
                    key='italic'
                    style={{ fontStyle: 'italic' }}
                    checked={selectedStyle?.fontStyle !== ''}
                    disabled={selectedStyle?.fromTmp}
                    onChange={(e) => changeHandler().fontStyle(e)}
                  />
                </Form.Item>
                <Form.Item label={t('underline')} style={{ display: 'inline-block', margin: '0 8px' }}>
                  <Checkbox
                    key='underline'
                    style={{ textDecoration: 'underline' }}
                    checked={selectedStyle?.textDecoration !== ''}
                    disabled={selectedStyle?.fromTmp}
                    onChange={(e) => changeHandler().textDecoration(e)}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                label="Marge du label"
                name='marginLabel'
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={selectedStyle?.borders?.radius}
              >
                <InputNumber
                  defaultValue={selectedStyle?.label?.margin}
                  min={1}
                  max={50}
                  onChange={(e) => changeHandler().marginLabel(e)}
                  disabled={selectedStyle?.fromTmp}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item label="Label à gauche" style={{ display: 'inline-block', margin: '0 8px' }}>
                <Checkbox
                  key='bold'
                  style={{ fontWeight: 'bold' }}
                  defaultChecked={selectedStyle?.box?.direction === 'rtl'}
                  disabled={selectedStyle?.fromTmp}
                  // checked={liveStylePreview()?.fontWeight !== ''}
                  onChange={(e) => changeHandler().directionLabel(e)}
                />
              </Form.Item>
              <Form.Item style={{ display: 'inline-block', margin: '0 8px' }} label='Couleur du texte'>
                <ColorSelector
                  onChange={(e) => changeHandler().color(e)}
                  disabled={selectedStyle.id === 0 || selectedStyle.fromTmp}
                  forcedValue={selectedStyle.id === 0 ? configuration.baseFontColor.label : selectedStyle?.color?.label}
                />
              </Form.Item>
            </Form.Item>
          </fieldset>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style du check
            </legend>
            <Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Taille de la case à cocher"
                name='sizeBox'
              // initialValue={styleRules.fontSize}
              >
                <InputNumber
                  defaultValue={selectedStyle?.box?.width}
                  min={minFontSize}
                  max={maxFontSize}
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().sizeBox(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Taille du check"
                name={`sizeCheck-${selectedStyle?.id}`}
              >
                <InputNumber
                  defaultValue={selectedStyle?.check?.width}
                  min={minFontSize}
                  max={maxFontSize}
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().sizeCheck(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Style de la case à cocher"
                name='styleCheck'
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <Select
                  disabled={selectedStyle?.fromTmp}
                  options={Object.entries(styleCheckbox).map(([key, value]) => ({
                    value: key,
                    label: <FontAwesomeIcon
                      icon={value}
                    />
                  }))}
                  onChange={(e) => changeHandler().styleBox(e)}
                  defaultValue={selectedStyle?.styleBox}
                ></Select>
              </Form.Item>

              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Couleur de la case item"
                name={`itemColorCheckBox-${selectedStyle?.id}`}
              >
                <ColorSelector
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().colorCheck(e)}
                  forcedValue={selectedStyle?.check?.color?.label}
                />
              </Form.Item>
            </Form.Item>
          </fieldset>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style de la box
            </legend>
            <Form.Item>


              <Form.Item
                label="Couleur des bordures de la case à cocher"
                name={`borderColorCheckBox-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <ColorSelector
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().borderColors(e)}
                  forcedValue={selectedStyle?.borders?.color?.label}
                />
              </Form.Item>
              <Form.Item
                label="Couleur de fond de la case à cocher"
                name={`backgroundColorBox-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <ColorSelector
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().changeBoxBackgroundColor(e)}
                  forcedValue={selectedStyle?.box.backgroundColor?.label}
                />
              </Form.Item>
              <Form.Item
                label="Taille de la bordure"
                name='borderCheckSize'
                style={{ display: 'inline-block', margin: '0 8px' }}
                initialValue={selectedStyle?.borders?.size}
              >
                <InputNumber
                  disabled={selectedStyle?.fromTmp}
                  defaultValue={selectedStyle?.borders?.size}
                  min={1}
                  max={10}
                  onChange={(e) => changeHandler().borderSize(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Radius de la bordure"
                name='borderCheckRadius'
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={styleRules.fontSize}
              >
                <InputNumber
                  disabled={selectedStyle?.fromTmp}
                  defaultValue={selectedStyle?.borders?.radius}
                  min={1}
                  max={10}
                  onChange={(e) => changeHandler().borderRadius(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label={t('checkbox-disposition')}
                name="disposition"
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <NavbarListButton
                  title={t('checkbox-disposition')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'grid-dividers', style: 'light' })} style={iconButtonStyle()} />}
                >
                  {Object.keys(dispostionButtons).map((buttonName) => {
                    return (
                      <Button
                        key={`${buttonName}`}
                        style={{
                          ...navbarButtonStyle,
                          marginBottom: '1px',
                        }}
                        disabled={selectedStyle?.fromTmp || false}
                        icon={dispostionButtons[buttonName].icon}
                        onClick={() => changeHandler().disposition(dispostionButtons[buttonName].disposition)}
                      />
                    );
                  })}
                </NavbarListButton>
              </Form.Item>
            </Form.Item>
          </fieldset>
        </div>
      </div>
    </div>
  );
};
export default TabsCheckbox;
