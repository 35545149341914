import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ANT Design
import { Button, Divider, Modal, notification, Tooltip, Tree } from 'antd';

import { ItemTypes } from '../../constants/constants';
import { useStore } from '../../store/store';
import './LeftSide.less';
import { displayTitleIndex, getTitlesConfig, selectLevelTitle } from '../../utils/titles';
import { scrollToItem } from '../Middle/InfinitePage';
import { useContainersToShow } from '../../hooks';
import { isATitleBox } from '../../utils/boxes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DownOutlined, ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const generateTreeFromFlatBoxes = ({ boxes, separator, titlesTypeConfig, hideTitleIndex, columns }) => {
  if (!boxes) return [];

  const boxMap = {};

  const checkIfParentExist = (key) => {
    if (!key || key.length === 0) return null; // Gestion des valeurs nulles ou vides

    if (!boxMap[key]) {
      // Recherche de l'index du parent
      const newParentKey = key.split('-').slice(0, -1).join('-');
      return checkIfParentExist(newParentKey);
    }
    return key;
  };

  // Construction de la structure arborescente
  boxes.forEach((box) => {
    if (!isATitleBox(box.type)) return;

    // Création des clés de parent et d'élément courant
    let parentKey = box.indexTitle.slice(0, box.indexTitle.length - 1).join('-');
    const currentKey = box.indexTitle.join('-');
    parentKey = checkIfParentExist(parentKey);

    // Création ou récupération de la box courante
    if (!boxMap[currentKey]) {
      const title = displayTitleIndex(
        box.content.indexTitle,
        separator,
        titlesTypeConfig,
        hideTitleIndex
      ) + box.text;
      boxMap[currentKey] = {
        ...box,
        key: box.id,
        title,
        column: columns.find((column) => column.id === box.columnId),
        indexTitle: box.indexTitle,
        children: []
      };
    }

    // Ajout de l'élément courant comme enfant de son parent
    if (parentKey) {
      if (!boxMap[parentKey]) {
        boxMap[parentKey] = { children: [] };
      }
      boxMap[parentKey].children.push(boxMap[currentKey]);
    }
  });

  const markLastChildren = (children) => {
    if (!children || children.length === 0) return;
    children.forEach((child, index) => {
      child.isLast = index === children.length - 1; // Dernier élément
      if (child.children && child.children.length > 0) {
        markLastChildren(child.children); // Répéter pour les niveaux imbriqués
      }
    });
  };

  // Filtre pour retourner uniquement les éléments de niveau racine
  const rootBoxes = Object.values(boxMap).filter((box) => box?.indexTitle?.length === 1);

  rootBoxes.forEach((root) => {
    markLastChildren(root.children);
  });

  return rootBoxes;
};

const selector = (state) => ({
  boxes: state.getSummaryBoxes(),
  updateIndexTitle: state.updateIndexTitle,
  updateBox: state.updateBox,
  moveBoxesIndex: state.moveBoxesIndex,
  defaultConfiguration: state.configuration,
  allBoxes: state.boxes,
  columns: state.columns,
  containers: state.containers,
  removeSectionContainers: state.removeSectionContainers,
  removeContainer: state.removeContainer,
  currentDocument: state.currentDocument
});
const itemSelector =
  () =>
    ({ configuration, unCollapseTitles }) => ({
      separator: configuration.separatorTitle,
      hideTitleIndex: configuration.hideTitleIndex,
      titlesTypeConfig: getTitlesConfig(configuration),
      unCollapseTitles
    });

const showConfirm = ({ deleteContainer, deleteSectionContainers,
  deleteAllContainerFromPdfToImageWithSameKey, t, fromSection,
  KeyPdfToImage, fromLinkedSection, message = 'title-delete-warning' }) => {

  const flexDirectionButton = (fromSection || KeyPdfToImage) ? "column" : "row"

  const modal = confirm({
    title: t(message),
    icon: <ExclamationCircleFilled />,
    cancelText: t('cancel'),
    okText: t('delete'),
    okType: 'danger',
    onOk() {
      deleteContainer();
    },
    onCancel() {
      // Handle onCancel logic if needed
    },
    footer: (
      <div style={{ display: "flex", flexDirection: flexDirectionButton, justifyContent: "end", gap: 10 }}>
        <Button onClick={() => modal.destroy()}>{t('cancel')}</Button>
        {fromSection && <Button danger onClick={async () => {
          await deleteSectionContainers()
          modal.destroy()
        }
        }>
          {t("section-delete-all-container")}
        </Button>}
        {KeyPdfToImage && <Button danger onClick={async () => {
          await deleteAllContainerFromPdfToImageWithSameKey()
          modal.destroy()
        }
        }>
          {t("pdf-to-image-delete-all-container")}
        </Button>}
        <Button danger disabled={fromLinkedSection} onClick={async () => {
          await deleteContainer()
          modal.destroy()
        }
        }>{t('delete')}</Button>
      </div>
    ),
  });
};

const SummarySide = () => {
  const { boxes, updateIndexTitle, updateBox, moveBoxesIndex, defaultConfiguration, allBoxes, columns, containers, removeSectionContainers, removeContainer, currentDocument } = useStore(selector);
  const { separator, titlesTypeConfig, hideTitleIndex, unCollapseTitles } = useStore(
    itemSelector()
  );
  const [gData, setGData] = useState(generateTreeFromFlatBoxes({ boxes, separator, titlesTypeConfig, hideTitleIndex, columns }));
  useEffect(() => {
    setGData(generateTreeFromFlatBoxes({ boxes, separator, titlesTypeConfig, hideTitleIndex, columns }));
  }, [boxes]);

  const [api, contextHolder] = notification.useNotification();
  const [currentDragTitle, setCurrentDragTitle] = useState(null);

  const openNotification = (message) => {
    api.warning({
      message: 'Déplacement interdit',
      description: message,
      placement: 'top',
    });
  };

  const titleType = {
    0: ItemTypes.TITLE,
    1: ItemTypes.TITLE_2,
    2: ItemTypes.TITLE_3,
    3: ItemTypes.TITLE_4,
  }

  const [expandedKeys] = useState();

  const { t } = useTranslation();
  const containersToShow = useContainersToShow()

  const onDragEnter = (info) => {
    // console.log(info);
  };
  const onDrop = async (info) => {

    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    // Fonction pour remonter aux parents avec `draw: true`
    const findAllBoxesInColumn = (columnId) => {

      return allBoxes.filter((box) => {
        if (box.columnId === columnId) {
          return box;
        }
      })
    }

    const data = [...gData];
    let dragObj;
    let boxesInColumn = [];

    // Remove the dragged node from its original position
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      const column = columns.find(column => column.id === item.columnId);
      if (column.drawMode) {

        boxesInColumn = findAllBoxesInColumn(item.columnId);
      }
      dragObj = item;
    });

    // Handle the drop based on whether it's dropped into a gap or as a child
    if (!info.dropToGap) {
      loop(data, dropKey, (item, index, arr) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);

        // Update the indexTitle to reflect its new position as a child
        dragObj.indexTitle = [...item.indexTitle, item.children.length];

      });

    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }

      dragObj.indexTitle = [i + 1];  // Update this logic depending on your tree structure
    }

    const typeTitle = dropPosition === 0 ? titleType[info.node.indexTitle.length] : info.node.type;

    const res = await moveBoxesIndex({ oldDragTitle: dragObj, aboveDropTitle: info, dropPosition, boxesInColumn });
    if (res !== undefined && res !== false) {
      openNotification(res);
    }

    if (
      boxesInColumn.length === 0 &&
      !dragObj?.from_linked_section &&
      !dragObj?.maskId &&
      (dragObj?.maskId === undefined || info?.node?.maskId !== dragObj?.maskId)
    ) {
      updateBox(dragObj.id, (box) => {
        box.content.indexTitle = info.node.indexTitle;
        box.type = typeTitle;
      })
      selectLevelTitle(typeTitle, { boxId: dragObj.id, updateBox, boxes, defaultConfiguration, updateIndexTitle, selectedBox: allBoxes.find(box => box.id === dragObj.id) });
    }

  };

  const handleOnDrop = async (info) => {
    await onDrop(info).then(() => {
      updateIndexTitle();
    });
  };

  const handleDeleteTitleAndThisContent = (node) => {
    const containerOfThisNode = containers.find(container => container.columnsIds.includes(node.columnId));
    const columnOfThisNode = columns.find(column => column.id === node.columnId);

    if (containerOfThisNode.sectionId) {
      showConfirm({
        deleteSectionContainers: () => removeSectionContainers(node.fromBoxId, { sectionId: containerOfThisNode?.sectionId, dateTimestamp: containerOfThisNode?.dateTimestamp }),
        deleteContainer: () => removeContainer(containerOfThisNode.id),
        t,
        fromSection: true,
        fromLinkedSection: containerOfThisNode?.from_linked_section
      })
    }
    else if (node.maskId || columnOfThisNode.drawMode) {
      // const message = node.maskId ? 'mask-delete-all' : 'draw-delete-all';
      showConfirm({
        // deleteContainer: () => removeContainer(containerOfThisNode.id),
        deleteContainer: () => {
          moveBoxesIndex({ oldDragTitle: node, aboveDropTitle: null, forDelete: true });
          updateIndexTitle();
        }, t
      })
    }
    else {
      showConfirm({
        deleteContainer: () => {
          moveBoxesIndex({ oldDragTitle: node, aboveDropTitle: null, forDelete: true });
          updateIndexTitle();
        }, t
      })
    }
  }


  return (
    <>
      <div>
        {boxes.length > 0 && (
          <Divider style={{ color: 'white', borderColor: 'white' }}>{t('summary')}</Divider>
        )}
      </div>
      {contextHolder}

      <Tree
        style={{ backgroundColor: currentDocument?.type === "opportunity" ? '#0b2239' : '#041598', color: 'white', "--custom-indent-size": "40px" }}
        className="draggable-tree custom-tree"
        defaultExpandedKeys={expandedKeys}
        draggable={{ icon: false }}
        onSelect={(_, box) => {
          unCollapseTitles({ index: box.node.indexTitle[0] });
          scrollToItem(box.node, containersToShow)
        }
        }
        onDragEnter={onDragEnter}
        onDragStart={(info) => setCurrentDragTitle(info.node)
        }
        onDragEnd={() => setCurrentDragTitle(null)}
        connecctDragPreview={false}
        allowDrop={({ dropNode }) => {
          if (dropNode.type === ItemTypes.TITLE_4) return false;
          else return true;
        }}
        titleRender={(node) => {
          if (currentDragTitle !== null &&
            (
              (node.from_linked_section && node.fromSectionId === currentDragTitle?.fromSectionId) ||
              (currentDragTitle?.column.drawMode && node.column.id === currentDragTitle?.column.id)
            )) {
            return <div style={{ color: "#f17024" }}>{node.title}</div>
          } else return <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }} className='container-title-summary'>
            <div style={{ maxWidth: 180, overflowWrap: "anywhere" }}>{node.title}</div>
            <div style={{ width: 20 }}>
              <span className="fa-layers fa-fw" style={{ marginLeft: 5 }}>
                {<FontAwesomeIcon
                  className='trash-icon'
                  icon={icon({ name: 'square', style: 'solid' })}
                  style={{ fontSize: 18, color: "#F6A3AD", marginRight: 1 }}
                  onClick={() => handleDeleteTitleAndThisContent(node)} />}
                <FontAwesomeIcon
                  className='trash-icon'
                  icon={icon({ name: 'times', style: 'solid' })}
                  style={{
                    fontSize: 18, color: "red"
                  }}
                  transform="shrink-4"
                  onClick={() => handleDeleteTitleAndThisContent(node)} />
              </span>
            </div>
          </div>

        }}
        onDrop={handleOnDrop}
        showLine
        switcherIcon={<DownOutlined style={{ color: "#aeaeae" }} />}
        treeData={gData}
      />
    </>
  );
};

export default React.memo(SummarySide);
