import React, { useCallback, useEffect, useRef } from 'react';
import { useStore } from '../../store/store';

// Li depth className to retrieve depth
const baseLiClassName = 'public-DraftStyleDefault-depth';
// Margin left's multiplier factors corresponding to li depth
const depthMarginCorrelation = {
  [`${baseLiClassName}0`]: 1.5,
  [`${baseLiClassName}1`]: 3,
  [`${baseLiClassName}2`]: 4.5,
  [`${baseLiClassName}3`]: 6,
  [`${baseLiClassName}4`]: 7.5,
};

const EditorWrapper = ({ children, boxId, editorState, box, textStyle }) => {
  const ref = useRef();
  const configurationFontSize = useStore((state) => state.configuration.fontSize)
  const wrapperId = `${boxId.split('.').join("")}-editor-wrapper`

  const stylizeLis = useCallback(
    (lis, isOl) => {
      lis?.forEach((li) => {
        // Retrieve block offsetKey
        const offsetKey = li.dataset.offsetKey;
        if (!offsetKey) return;

        // Retrieve first span before the marker/before to get the first
        // character's style
        const firstSpan = li.querySelector(
          `span[data-offset-key="${offsetKey}"]`
        );
        if (!firstSpan) return;
        const styleToCopy = firstSpan.style;

        // Find or create style for this li
        let styleElem = document.getElementById(`style-${offsetKey}-${wrapperId}`);
        if (!styleElem) {
          styleElem = document.createElement('style');
          styleElem.id = `style-${offsetKey}-${wrapperId}`;
          document.head.appendChild(styleElem);
        }

        // Apply first character's style to marker or before
        let innerHtml = `#${wrapperId}
          li[data-offset-key="${offsetKey}"] {.public-DraftStyleDefault-block::before {font-size: ${styleToCopy['font-size']};color: ${styleToCopy['color']};}}
          li[data-offset-key="${offsetKey}"]::marker {font-size: ${styleToCopy['font-size']};color: ${styleToCopy['color']};}
          li[data-offset-key="${offsetKey}"]::before {font-size: ${styleToCopy['font-size']};color: ${styleToCopy['color']}; font-family: ${styleToCopy['font-family']};font-weight: ${styleToCopy['font-weight']};font-style: ${styleToCopy['font-style']};text-decoration: ${styleToCopy['text-decoration']};}
          `;
        styleElem.innerHTML = innerHtml;

        // Put corresponding margin-left on li depending on li depth and font-size
        if (styleToCopy['font-size']) {
          const fontSize = parseFloat(styleToCopy['font-size']);
          // Retrieve li depth className
          const liDepthClassName = Array.from(li.classList).find((className) =>
            className.includes(baseLiClassName)
          );
          // Retrieve li margin-left's multiplier factor
          const marginUsed = depthMarginCorrelation[liDepthClassName];
          // Add difference between current fontSize and configurationFontSize
          // to default margin-left (pixelsToAdd is always positive)
          const pixelsToAdd = Math.max(0, fontSize - configurationFontSize);
          li.style['margin-left'] = `${marginUsed * configurationFontSize + pixelsToAdd
            }px`;
        }
      });
    },
    [configurationFontSize, wrapperId]
  );

  useEffect(() => {
    const uls = ref.current.querySelectorAll('ul');
    uls?.forEach((ul) => {
      const lis = ul.querySelectorAll('li');
      stylizeLis(lis, false);
    });
    const ols = ref.current.querySelectorAll('ol');
    ols?.forEach((ul) => {
      const lis = ul.querySelectorAll('li');
      stylizeLis(lis, true);
    });
  }, [editorState, stylizeLis]);

  useEffect(() => {
    if (!box) return;
    const editorWrapper = document.getElementById(`${box.id.split('.').join("")}-editor-wrapper`);

    if (editorWrapper) {
      const spans = editorWrapper.querySelectorAll("span");

      const draftStyleDefaultBlocks = editorWrapper.querySelectorAll(".public-DraftStyleDefault-block");

      draftStyleDefaultBlocks.forEach(block => {
        block.style.justifyContent = box.content.alignment || textStyle.positions.horizontal;
      });

      spans.forEach(span => {
        span.style.alignSelf = box.content?.imagePosition || textStyle?.imagePosition;
        const pos = box.content?.imagePosition || textStyle?.imagePosition
        const positionMap = {
          center: "center center",
          start: "top center",
          end: "bottom center",
        };

        if (positionMap[pos]) {
          span.style.backgroundPosition = positionMap[pos];
        }
      });
    }

  }, [box?.content.alignment, box?.content?.imagePosition, box?.id, editorState, textStyle?.imagePosition, textStyle?.positions.horizontal]);

  return (
    <div ref={ref} id={wrapperId}>
      {children}
    </div>
  )
}

export default EditorWrapper
