import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { GLOSSARY, ItemTypes } from '../../constants/constants';
import { useStore } from '../../store/store';
import { CopyBox, CopyContainer, isASelectionBox } from '../../utils/boxes';
import ConfigurationModal from '../RightSide/configurationModal/ConfigurationModal';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from './NavbarButton';
import { Separator } from './Navbar';

const selector = (box) => (state) => ({
  currentDocument: state.currentDocument,
  isAdmin: state?.user?.isAdmin,
  removeBox: box?.id && state?.removeBox,
  setCopy: state.setCopy,
  updateBox: state.updateBox,
  setBoxMovable: state.setBoxMovable,
  boxVariableSelectedId: state.boxVariableSelectedId,
  selectedContainer: state.selectedContainer,
  boxes: state.boxes,
  columns: state.columns,
  isSelectionBox: isASelectionBox(box?.type),
});

export const RightPropertiesComponent = ({
  selectedBox,
  configurationPanelVisible,
  setConfigurationPanelVisible,
  configurationTab,
  setConfigurationTab
}) => {
  const {
    currentDocument,
    isAdmin,
    removeBox,
    setCopy,
    updateBox,
    setBoxMovable,
    boxVariableSelectedId,
    selectedContainer,
    boxes,
    columns,
    isSelectionBox
  } = useStore(selector(selectedBox));
  const { t } = useTranslation();

  const deleteButton =
    selectedBox && !boxVariableSelectedId &&
    (selectedBox.type === 'text' ||
      selectedBox.type === 'table' ||
      selectedBox.type === 'img' ||
      selectedBox.type === 'shape' ||
      selectedBox.type === 'line' ||
      selectedBox.type === 'title1' ||
      selectedBox.type === 'title2' ||
      selectedBox.type === 'title3' ||
      selectedBox.type === 'title4' ||
      selectedBox.type === 'summary' ||
      selectedBox.type === 'line_break' ||
      selectedBox.type === ItemTypes.CHECKBOXS_VARIABLE ||
      selectedBox.type === ItemTypes.SELECTOR_VARIABLE ||
      selectedBox.type === ItemTypes.TEXT_VARIABLE ||
      selectedBox.type === ItemTypes.IMG_VARIABLE ||
      selectedBox.type === ItemTypes.TABLE_VARIABLE ||
      selectedBox.type === ItemTypes.GROUP_BOXES);

  useEffect(() => {
    setBoxMovable(!configurationPanelVisible);
  }, [configurationPanelVisible, setBoxMovable]);

  const handleCopycontainer = () => {
    CopyContainer({ boxes, columns, selectedContainer, setCopy })
  }

  const disableCopyButton = () => {
    if (isSelectionBox) return true
    const canCopied = boxes
      ?.filter(({ columnId }) => selectedContainer?.columnsIds.includes(columnId))
      ?.some((box) => box.type === ItemTypes.SECTION || box.type === ItemTypes.MASK || box.type === ItemTypes.FILE_PDF);
    const maskId = columns.find(({ id }) => selectedContainer?.columnsIds.includes(id))?.maskId;
    if (maskId && !selectedBox) return true;
    if (selectedBox) return false
    if (!canCopied && selectedContainer) return false
    if (canCopied) return true
    if (!selectedContainer) return true
  }

  return (
    <>
      {isAdmin && configurationPanelVisible && (
        <ConfigurationModal
          open={true}
          handleOk={() => {
            setConfigurationTab('Couleurs');
            setConfigurationPanelVisible(false);
          }}
          configurationTab={configurationTab}
          setConfigurationTab={setConfigurationTab}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <NavbarButton
          tooltipContent={t('move-front')}
          onClick={
            () =>
              updateBox(selectedBox?.id, (box) => {
                box.zIndex += 1;
              })
          }
          disabled={!deleteButton}
          hidden={!deleteButton}
          icon={<FontAwesomeIcon icon={icon({ name: 'layer-plus', style: 'light' })} style={iconButtonStyle()} />}
        />
        <NavbarButton
          tooltipContent={t('move-back')}
          onClick={
            () =>
              updateBox(selectedBox?.id, (box) => {
                box.zIndex = Math.max(box.zIndex - 1, 0);
              })
          }
          disabled={!deleteButton}
          hidden={!deleteButton}
          icon={<FontAwesomeIcon icon={icon({ name: 'layer-minus', style: 'light' })} style={iconButtonStyle()} />}
        />
        <Separator />
        <NavbarButton
          tooltipContent={t('copy')}
          onClick={() => {
            if (selectedBox) CopyBox(selectedBox, setCopy)
            if (!selectedBox && selectedContainer) handleCopycontainer()
          }
          }
          disabled={disableCopyButton()}
          icon={<FontAwesomeIcon icon={icon({ name: 'copy', style: 'light' })} style={iconButtonStyle()} />}
        />
        <Popconfirm
          title={GLOSSARY.REMOVE_BOX(0)}
          cancelText='Annuler'
          placement='bottom'
          getPopupContainer={(trigger) => trigger.parentElement}
          onConfirm={() => removeBox(selectedBox.id)}
        >
          <NavbarButton
            tooltipContent={t('delete')}
            disabled={!deleteButton}
            icon={<FontAwesomeIcon icon={icon({ name: 'trash', style: 'light' })} style={{
              ...iconButtonStyle(),
              color: deleteButton ? '#FF0000' : 'black'
            }} />}
          />
        </Popconfirm>
        <Separator />
        {/* Configuration button */}
        <NavbarButton
          tooltipContent={t('configuration')}
          onClick={() => { setConfigurationPanelVisible(true) }}
          disabled={!(currentDocument.type === 'template' && isAdmin)}
          icon={<FontAwesomeIcon icon={icon({ name: 'gear', style: 'light' })} style={iconButtonStyle()} />}
        />
        <Separator />
      </div>
    </>
  );
};
