import { ItemTypes, uuid } from '../constants/constants';
import { pageHeight, pageWidth } from '../constants/gridConfig';
import { mutateAddBoxes, mutateRemoveBox } from './boxes';
import { mutateUpdateColumn } from './columns';
import { mutateUpdateContainer } from './containers';

export const mutateCreateMask = ({ draft, name, KeyPdfToImage = null }) => {
  const maskId = uuid();
  draft.masks = [
    ...draft.masks,
    { id: maskId, name, boxes: [], editableMask: true, KeyPdfToImage },
  ];
};

export const mutateInjectMask = ({ draft, maskId, boxId }) => {
  const maskContent = draft.masks.find((m) => m.id === maskId);
  const box = draft.boxes.find((b) => b.id === boxId);
  const column = draft.columns.find((c) => c.id === box.columnId);
  const container = draft.containers.find((c) =>
    c.columnsIds.includes(box.columnId)
  );
  column.maskId = maskId;
  // TODO : check existence
  column.updateTimestamp = maskContent.updateTimestamp;
  const boxesToAdd = maskContent.boxes.map((b) => {
    return {
      ...b,
      oldId: b.id,
      id: uuid(),
      columnId: column.id,
    };
  });
  boxesToAdd.forEach((b) => {
    if (b.type === ItemTypes.GROUP_BOXES) {
      const boxIds = boxesToAdd
        .filter((bAdd) => b.boxIds.includes(bAdd.oldId))
        .map(({ id }) => id);
      b.boxIds = boxIds;
    }
  });
  boxesToAdd.forEach((b) => {
    delete b.oldId;
  });
  mutateUpdateContainer(draft, {
    ...container,
    height: draft.landscape ? pageWidth : pageHeight,
    editable: maskContent?.editableMask ?? true,
  });
  mutateUpdateColumn(draft, column);
  // remove mask "template" box
  mutateRemoveBox(draft, boxId);
  mutateAddBoxes({ draft, newBoxes: boxesToAdd });
};
