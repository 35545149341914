import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';
import { NavbarInputNumber } from '../Layout/NavbarInputNumber';
import { NO_ROTATION_SHAPES } from '../Shape/shapeUtils';
import './../common/Utils.css';
import { defaultZoom, dynamicZoom, ItemTypes } from '../../constants/constants';
import { isASelectionBox } from '../../utils/boxes';
import { Separator } from '../Layout/Navbar';

const NO_ROTATION_TYPES = []

const selector =
  () =>
    ({ updateBox, setBoxMovable, boxes, selectedBoxId, columns, maskEditing, masks, configuration }) => {
      const boxesToUse = maskEditing?.length > 0 ? masks.filter((m) => maskEditing.includes(m.id)).map((m) => m.boxes).flat() : boxes
      const box = boxesToUse.find((b) => b.id === selectedBoxId)
      const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;
      return {
        updateBox,
        setBoxMovable,
        box,
        drawMode: maskEditing?.length > 0 || !box?.columnId || columns.find((c) => c.id === box.columnId).drawMode,
        isSelectionBox: isASelectionBox(boxes.find((b) => b.id === selectedBoxId)?.type),
        boxes: boxesToUse,
        zoomFactor
      };
    };

export const BoxRotationInterface = () => {
  const { t } = useTranslation();
  const { updateBox, box, drawMode, isSelectionBox, boxes, zoomFactor } = useStore(selector());
  const canRotate = drawMode && !isSelectionBox && (!NO_ROTATION_TYPES.includes(box?.type) ||
    (box?.content?.shape && !NO_ROTATION_SHAPES.includes(box?.content.shape)))

  const rotateChildren = (rotation) => {
    const getChildPosition = (child) => {
      const rad = (rotation * Math.PI) / 180;

      // Calculate parent element's center
      const parentCenter = {
        x: box.left + box.width / 2,
        y: box.top + box.height / 2
      };

      // Calculate offset from parent's center to child's original position
      const offset = {
        x: child.left - parentCenter.x + child.width / 2,
        y: child.top - parentCenter.y + child.height / 2
      };

      // Apply rotation to offset
      const rotatedX = offset.x * Math.cos(rad) - offset.y * Math.sin(rad);
      const rotatedY = offset.x * Math.sin(rad) + offset.y * Math.cos(rad);

      // Calculate final position relative to parent's center
      return {
        x: parentCenter.x + rotatedX - child.width / 2,
        y: parentCenter.y + rotatedY - child.height / 2
      };
    };

    const boxesToRotate = boxes.filter(({ id }) => box.boxIds.includes(id));

    boxesToRotate.forEach((b) => {
      const originalEl = document.getElementById(`box-container-${b.id}`);
      const clone = originalEl.cloneNode(true);
      clone.id = 'temp-clone';
      clone.style.visibility = 'hidden';
      const currentTransform = clone.style.transform;

      if (currentTransform && currentTransform !== 'none') {
        const withoutRotate = currentTransform.replace(/\s*rotate\([^)]*\)/g, '');
        clone.style.transform = withoutRotate;
      }

      originalEl.parentNode.insertBefore(clone, originalEl.nextSibling);
      const cloneRect = clone.getBoundingClientRect();
      updateBox(b.id, (bo) => {
        bo.rotation = rotation + ~~b.originalRotation
        const positions = getChildPosition({ top: bo.originalTop, left: bo.originalLeft, width: cloneRect.width / zoomFactor, height: cloneRect.height / zoomFactor })
        bo.left = positions.x
        bo.top = positions.y
      }, {
        shouldNotMeasureGroupBox: true
      });
      clone.parentNode.removeChild(clone);
    });
  }

  // update which shape

  const rotateBox = (box, value = null) => {
    if (Number.isInteger(value)) {
      if (box.type === ItemTypes.LINE && value > 90) return
      const rotation = value % 360
      updateBox(box.id, (box, draft) => {
        box.rotation = rotation;
      });
      if (box.type === ItemTypes.GROUP_BOXES) {
        rotateChildren(rotation)
      }
      return;
    }
    if (box.type === ItemTypes.LINE && box.rotation + 90 !== 90) {
      updateBox(box.id, (box) => {
        const rotation = 0
        box.rotation = rotation;
      });
      return
    }

    const rotation = ((box.rotation ?? 0) + 90) % 360
    updateBox(box.id, (box) => {
      box.rotation = rotation;
    });

    if (box.type === ItemTypes.GROUP_BOXES) {
      rotateChildren(rotation)
    }
  };

  return (
    <>
      {(!box || !canRotate) ||
        <div className='navbar__common-properties-container'>
          <div className='navbar__commontop-properties-container'>
            <NavbarInputNumber
              min={0}
              max={360}
              disabled={!box || !canRotate}
              hidden={!box || !canRotate}
              tooltipContent={t('rotation')}
              value={box?.rotation ?? 0}
              suffix='°'
              onChange={(value) => rotateBox(box, value)}
              defaultValue={0}
            />
            <NavbarButton
              tooltipContent={t('rotation')}
              onClick={() => rotateBox(box)}
              hidden={!box || !canRotate}
              icon={< FontAwesomeIcon icon={icon({ name: 'rotate-right', style: 'light' })} style={iconButtonStyle()} />}
            />
          </div>
        </div>}
      <Separator hidden={!box || !canRotate} />
    </>
  );
};
