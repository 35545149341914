import { useDragLayer } from 'react-dnd'
import Box from './Box.jsx'
import { defaultZoom, dynamicZoom, ItemTypes } from '../../constants/constants.js'
import { useStore } from '../../store/store.js'

const layerStyles = {
  position: 'absolute',
  left: 0,
  top: 0,
  pointerEvents: 'none',
  zIndex: 100,
  width: '100%',
  height: '100%',
  cursor: 'grabbing !important'
}

// const previewStyleContainer = {
//   position: 'relative',
//   width: '100%',
//   height: '100%',
// }

const getItemStyles = (top, left, rotation, differenceOffset) => {
  if (!differenceOffset) { return { display: 'none' } }
  const transform = rotation && `rotate(${rotation}deg)`
  return {
    position: 'absolute',
    top: top + ~~differenceOffset.y,
    left: left + ~~differenceOffset.x,
    transform,
    // transform,
    // WebkitTransform: transform,
  }
}

export const CustomDragLayer = ({ id, drawMode, zoom }) => { // _iuhevjh
  const { isDragging, item, itemType, differenceOffset } =
    useDragLayer((monitor) => {
      const differenceOffset = monitor.getDifferenceFromInitialOffset();

      const zoomFactor = (zoom || dynamicZoom() || defaultZoom) / 100;

      const adjustedDifferenceOffset = {
        x: differenceOffset?.x / zoomFactor,
        y: differenceOffset?.y / zoomFactor
      };

      return {
        itemType: monitor.getItemType(),
        item: monitor.getItem(),
        differenceOffset: adjustedDifferenceOffset,
        isDragging: monitor.isDragging(),
      }
    })
  const isHere = item?.parentId === id;
  const groupBoxes = useStore(({ boxes, masks }) => itemType === ItemTypes.GROUP_BOXES ? [
    ...boxes,
    ...masks.map((m) => m.boxes).flat()
  ].filter((b) => item.boxIds.includes(b.id)) : [])
  if (!isDragging || itemType === "newBox" || !isHere) {
    return null
  }

  const renderItem = (element) => {
    return <div
      style={getItemStyles(element.top, element.left, element.rotation, differenceOffset)}
    >
      <Box id={element.id} asPreview drawMode={drawMode} />
    </div>
  }

  return (
    <div id="drag-layer" style={layerStyles}>
      {renderItem(item)}
      {itemType === ItemTypes.GROUP_BOXES && groupBoxes.map((b) => renderItem(b))}
    </div>
  )
}
