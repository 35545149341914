
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button,
  Input,
  Row,
  Switch,
  Table,
  Col,
  Tooltip,
  Popconfirm,
  Form,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import { useCallback, useState } from 'react';
import { useStore } from '../../../store/store';
import ColorSelector from '../../common/ColorSelector';
import './tableConfig.css';
import { ItemTypes, borderIcons, defaultTextConfiguration, styleCheckbox } from '../../../constants/constants';
import TitleFontSelector from '../../common/TitleFontSelector';
import { useTranslation } from 'react-i18next';
import { marginOnPageButton, maxFontSize, minFontSize, pageHeight, pageWidth } from '../../../constants/gridConfig';
import { BlockTextColor, BlockTextStyle } from '../../common/TitleConfiguration';
import { BorderOuterOutlined } from '@ant-design/icons';
import { getBackgroundBorderStyle, iconButtonStyle, mergeConfigurationTextStyles, navbarButtonStyle } from '../../../utils/styles';
import { NavbarListButton } from '../../Layout/NavbarListButton';
import CheckboxCustom from '../../common/CheckboxCustom';
import { getBoxBorder, hasAllBordersOn } from '../../RichEditor/utils';
import { Checkable } from '../../common/Checkable';
import FontPicker from '../../common/FontPicker';
const selector = ({
  templates,
  configuration,
  setSelectedStyle,
  currentTemplate,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
  styleRules,
  landscape,
  tmp
}) => ({
  templates,
  configuration,
  setSelectedStyle,
  templatable: currentTemplate.templatable,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
  styleRules,
  landscape,
  tmp
});

export const TextTabs = () => {
  const { configuration, setConfiguration, landscape, tmp } = useStore(selector);
  const [selectedStyle, setSelectedStyle] = useState(mergeConfigurationTextStyles(configuration, tmp)[0]);
  const [newStyleName, setNewStyleName] = useState('');
  const [isEditingStyleName, setIsEditingStyleName] = useState(false);
  const { t } = useTranslation();
  let styleList = [...mergeConfigurationTextStyles(configuration, tmp)];
  const styleData = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: (18 + 16) * 3 + 16,
    },
    {
      title: 'Style',
      dataIndex: 'style',
      key: 'style',
      onCell: (element, rowIndex) => {
        return {
          key: `style-config-${rowIndex}`,
          onClick: (event) => {
            handleSelectedStyle(element);
          },
        };
      },
    }
  ];

  const handleRemoveStyle = (idToRemove) => {
    const newtextStyles = configuration.textStyles.filter(style => style.id !== idToRemove);
    setConfiguration({
      ...configuration.textStyles,
      textStyles: newtextStyles,
    });
  };

  const handleRenameStyle = (ID, event) => {
    let styleToRename = {
      ...configuration.textStyles.find((element) => element.id === ID),
      name: event.target.value,
    };
    setConfiguration({
      textStyles: [
        ...configuration.textStyles.map((tableStyle) => {
          if (tableStyle.id === ID) {
            return styleToRename;
          }
          return tableStyle;
        }),
      ],
    });
    setIsEditingStyleName(null);
    setEditedName('');
    setSelectedStyle(styleToRename);
  };

  const handleCopyStyle = (copiedId) => {
    const copiedStyle = configuration.textStyles.find((s) => s.id === copiedId);
    let newStyle = {
      ...copiedStyle,
      id: configuration.textStylesIdCount + 1,
      name: `${copiedStyle.name} - Copie`,
    };
    let textStyles = [
      ...configuration.textStyles,
      newStyle,
    ];

    setConfiguration({
      ...configuration,
      textStyles,
      textStylesIdCount: configuration.textStylesIdCount + 1,
    });
  };

  const [editedName, setEditedName] = useState('');
  const dataSourceMapped = [];

  const iconStyle = {
    margin: '.5rem',
    fontSize: '1.5em',
  }
  mergeConfigurationTextStyles(configuration, tmp).forEach((element) => {
    const cantEdit = element.id === 0 || element.fromTmp === true;
    const textStyleLocked = element.fromTmp === true;
    dataSourceMapped.push({
      key: `tableStyle-${element.id}`,
      id: element.id,
      style:
        isEditingStyleName === element.id ? (
          <Input
            value={editedName}
            id='styleNameInput2'
            style={{ width: '60%' }}
            onChange={(e) => setEditedName(e.target.value)}
            onPressEnter={(e) => {
              if (!cantEdit && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
            onBlur={(e) => {
              if (!cantEdit && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
          />
        ) : (
          element.name
        ),
      actions: (
        <div>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Renommer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'edit', style: 'light' })}
              style={{
                ...iconStyle,
                color: cantEdit ? 'lightgray' : 'black',
              }}
              onClick={cantEdit ? null : (e) => {
                setIsEditingStyleName(element.id);
                setEditedName(element.name);
              }}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Dupliquer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'copy', style: 'light' })}
              style={{
                ...iconStyle,
                color: textStyleLocked ? 'lightgray' : 'black',
              }}
              onClick={textStyleLocked ? null : (e) => handleCopyStyle(element.id)}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Supprimer'}
          >
            {cantEdit ? (
              <FontAwesomeIcon
                icon={icon({ name: 'trash', style: 'light' })}
                style={{
                  ...iconStyle,
                  color: 'lightgray'
                }}
              />
            ) : (
              <Popconfirm
                title='Voulez vous vraiment supprimer ce style ?'
                onConfirm={() => { handleRemoveStyle(element.id); }}
                okText='Oui'
                cancelText='Non'
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'trash', style: 'light' })}
                  style={{
                    ...iconStyle,
                    color: 'red'
                  }}
                />
              </Popconfirm>
            )}
          </Tooltip>
        </div>
      ),
    });
  });

  const [isAddTableStyleLoading, setIsAddTableStyleLoading] = useState(false);

  const handleSelectedStyle = (e) => {
    mergeConfigurationTextStyles(configuration, tmp).forEach((element) => {
      if (element.id === e.id) {
        setSelectedStyle(element);
      }
    });
    return selectedStyle;
  };

  const loadingAnimation = () => {
    setIsAddTableStyleLoading(true);
    setTimeout(() => {
      setIsAddTableStyleLoading(false);
    }, 1000);
  };

  // const handleChange
  const handleSaveChanges = (updatedTableStyle) => {
    const updatedTableStyleIndex = configuration.textStyles.findIndex(
      (element) => element.id === updatedTableStyle.id
    );
    let newtextStyles = [...configuration.textStyles];
    newtextStyles[updatedTableStyleIndex] = updatedTableStyle;
    setSelectedStyle(updatedTableStyle);
    setConfiguration({
      ...configuration.textStyles,
      textStyles: newtextStyles
    });
  };

  const handleAddTableStyle = (e) => {
    loadingAnimation();
    if (newStyleName.length > 0) {
      const newStyle = {
        ...defaultTextConfiguration,
        id: configuration.textStylesIdCount,
        name: newStyleName,
      };
      let textStyles = [
        ...configuration.textStyles,
        newStyle,
      ];
      setConfiguration({
        ...configuration,
        textStyles,
        textStylesIdCount: configuration.textStylesIdCount + 1,
      });
      setSelectedStyle({ ...newStyle });
      setNewStyleName('');
    } else {
      alert('Un nom de template est obligatoire');
    }
  };

  const handleChangeFontSize = useCallback(() => (value) => {

    handleSaveChanges({
      ...selectedStyle,
      fontSize: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBackgroundColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      backgroundColor: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBorderColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      border: {
        ...selectedStyle.border,
        color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style }
      }
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBorderSize = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      border: {
        ...selectedStyle.border,
        size: value,
      }
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeBorderRadius = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      border: {
        ...selectedStyle.border,
        radius: value,
      }
    });
  }, [handleSaveChanges, selectedStyle]);



  const handleChangeSeparator = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      separator: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeWidth = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      width: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeBorderPlace = useCallback(() => (value) => {
    if (value === 'all') {
      handleSaveChanges({
        ...selectedStyle,
        border: {
          ...selectedStyle.border,
          top: true,
          bottom: true,
          left: true,
          right: true,
        },
      });
    } else {
      handleSaveChanges({
        ...selectedStyle,
        border: {
          ...selectedStyle.border,
          [value]: !selectedStyle.border[value],
        },
      });
    }

  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangePaddingTop = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      paddingTopBottom: value,
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangePaddingLateral = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      paddinngLeftRight: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeFontFamily = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontFamily: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangePosition = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      positions: { horizontal: value },
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleImagePositionInText = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      imagePosition: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeFontWeight = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontWeight: value ? 'bold' : '',
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeTextDecoration = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      textDecoration: value ? 'underline' : '',
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeFontStyle = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontStyle: value ? 'italic' : '',
    });
  }, [handleSaveChanges, selectedStyle]);

  const changeHandler = useCallback(() => ({
    borderRadius: handleChangeBorderRadius(),
    backgroundColor: handleChangeBackgroundColor(),
    borderSize: handleChangeBorderSize(),
    borderColor: handleChangeBorderColor(),
    color: handleChangeColor(),
    fontSize: handleChangeFontSize(),
    paddingTop: handleChangePaddingTop(),
    paddingLateral: handleChangePaddingLateral(),
    position: handleChangePosition(),
    width: handleChangeWidth(),
    separator: handleChangeSeparator(),
    borderPlace: handleChangeBorderPlace(),
    fontFamily: handleChangeFontFamily(),
    fontWeight: handleChangeFontWeight(),
    textDecoration: handleChangeTextDecoration(),
    fontStyle: handleChangeFontStyle(),
    imagePositionInText: handleImagePositionInText(),
  }), [
    handleChangeBackgroundColor,
    handleChangeBorderRadius,
    handleChangeColor,
    handleChangeBorderColor,
    handleChangeBorderSize,
    handleChangeFontSize,
    handleChangePaddingTop,
    handleChangePosition,
    handleChangeWidth,
    handleChangeSeparator,
    handleChangeBorderPlace,
    handleChangePaddingLateral,
    handleChangeFontFamily,
    handleChangeFontWeight,
    handleChangeTextDecoration,
    handleChangeFontStyle,
    handleImagePositionInText,
  ])



  const getStyle = () => {
    let justifyContent = 'start';

    if (selectedStyle?.positions.horizontal === "right") {
      justifyContent = 'end';
    } else if (selectedStyle?.positions.horizontal === "center") {
      justifyContent = 'center';
    }
    return {
      ...getBackgroundBorderStyle(selectedStyle?.backgroundColor, selectedStyle?.border,
        {
          color: selectedStyle?.color?.style,
          fontSize: selectedStyle?.fontSize,
          display: 'flex',
          justifyContent,
          fontFamily: selectedStyle?.fontFamily,
          fontWeight: selectedStyle?.fontWeight,
          fontStyle: selectedStyle?.fontStyle,
          textDecoration: selectedStyle?.textDecoration,
        }),
      width: `${selectedStyle?.width}px`,
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div style={{ textAlign: 'center', width: "40%" }}>
        <h5>Liste des styles des champs textes</h5>
        <div
          style={{
            margin: '2rem 0',
            padding: '0 2rem',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Input
            placeholder='Nom du nouveau Style'
            value={newStyleName}
            onChange={(e) => {
              e.preventDefault();
              setNewStyleName(e.target.value);
            }}
            onPressEnter={handleAddTableStyle}
          />
          <Button
            type='primary'
            htmlType='submit'
            loading={isAddTableStyleLoading}
            style={{ marginLeft: '.5rem' }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={handleAddTableStyle}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'plus', style: 'light' })}
              style={{ fontSize: '1.5em' }}
            />
          </Button>
        </div>
        <Table
          columns={styleData}
          dataSource={dataSourceMapped}
          rowClassName={(record, _rowIndex) => { return record.style === selectedStyle.name ? 'tableSelectedRow' : '' }}
          pagination={false}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "60%", margin: 20 }}>
        <div style={{
          ...getStyle(),
        }}>
          <div style={{ padding: `${selectedStyle?.paddingTopBottom}px ${selectedStyle?.paddinngLeftRight}px` }}>preview</div>
        </div>
        <div style={{ width: "100%" }}>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style du choix
            </legend>
            <Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Couleur de fond"
                name={`backgroundColor`}
              >
                <ColorSelector
                  onChange={(e) => changeHandler().backgroundColor(e)}
                  forcedValue={selectedStyle?.backgroundColor?.label}
                  disabled={selectedStyle?.fromTmp}
                />
              </Form.Item>

              <Form.Item
                label="Couleur des bordures"
                name={`borderColorCheckBox`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <ColorSelector
                  onChange={(e) => changeHandler().borderColor(e)}
                  forcedValue={selectedStyle?.border?.color?.label}
                  disabled={selectedStyle?.fromTmp}
                />
              </Form.Item>
              <Form.Item
                label="Taille de la bordure"
                name={`borderSize-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
                disabled={selectedStyle?.fromTmp}
              >
                <InputNumber
                  defaultValue={selectedStyle?.border?.size}
                  min={0}
                  max={20}
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().borderSize(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Radius de la bordure"
                name={`borderRadius-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <InputNumber
                  defaultValue={selectedStyle?.border.radius}
                  min={0}
                  disabled={selectedStyle?.fromTmp}
                  max={20}
                  onChange={(e) => changeHandler().borderRadius(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Taille du champ texte dans les colonnes dessins"
                name={`widthSelector-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <div style={{ display: 'flex', alignItems: "center", gap: 5 }}>
                  <InputNumber
                    defaultValue={selectedStyle?.width}
                    min={0}
                    disabled={selectedStyle?.fromTmp}
                    max={800}
                    onChange={(e) => changeHandler().width(e)}
                    style={{ marginLeft: '5px' }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                label="Marge hauteur"
                name={`paddingTop-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <InputNumber
                  defaultValue={selectedStyle?.paddingTopBottom}
                  min={0}
                  max={40}
                  disabled={selectedStyle?.fromTmp}
                  onChange={(e) => changeHandler().paddingTop(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Marge latérale"
                name={`paddingLeft-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
                disabled={selectedStyle?.fromTmp}
              // initialValue={styleRules.fontSize}
              >
                <InputNumber
                  defaultValue={selectedStyle?.paddinngLeftRight}
                  min={0}
                  max={40}
                  onChange={(e) => changeHandler().paddingLateral(e)}
                  style={{ marginLeft: '5px' }}
                  disabled={selectedStyle?.fromTmp}
                />
              </Form.Item>

              {['top', 'bottom', 'left', 'right'].map((border) => (
                <Form.Item
                  name={`border-${border}-${selectedStyle?.id}`}
                  style={{
                    display: 'inline-block',
                    marginBottom: '0px',
                  }}
                >
                  <Button
                    key={`border-${border}`}
                    disabled={selectedStyle?.fromTmp}
                    style={{ marginRight: marginOnPageButton, marginBottom: 0 }}
                    onClick={() => changeHandler().borderPlace(border)}
                    className={`RichEditor-styleButton ${selectedStyle?.border[border]
                      ? '  RichEditor-activeButton'
                      : ''
                      }`}
                    icon={borderIcons[border]}
                  />
                </Form.Item>
              ))}
              <Form.Item
                name={`border-all-${selectedStyle?.id}`}
                style={{
                  display: 'inline-block',
                  marginBottom: '0px'
                }}
              >
                <Button
                  disabled={selectedStyle?.fromTmp}
                  className={`RichEditor-styleButton ${selectedStyle?.border?.right === true &&
                    selectedStyle?.border.left === true &&
                    selectedStyle?.border.top === true &&
                    selectedStyle?.border.bottom === true
                    ? '  RichEditor-activeButton'
                    : ''
                    }`}
                  onClick={() => changeHandler().borderPlace('all')}
                  icon={<BorderOuterOutlined />}
                />
              </Form.Item>

            </Form.Item>
          </fieldset>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style du texte
            </legend>
            <Form.Item>
              <Form.Item style={{ display: 'inline-block', margin: '0 8px' }}
                label='Couleur du texte'
                name={`color-${selectedStyle?.id}`}
              >

                <ColorSelector
                  onChange={(e) => changeHandler().color(e)}
                  disabled={selectedStyle.id === 0 || selectedStyle?.fromTmp}
                  forcedValue={selectedStyle.id === 0 ? configuration.baseFontColor.label : selectedStyle?.color?.label}
                />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Police des labels"
                name={`fontFamily-${selectedStyle?.id}`}
              >
                <FontPicker onChange={(e) => changeHandler().fontFamily(e)}
                  disabled={selectedStyle.id === 0 || selectedStyle?.fromTmp}
                  forcedValue={selectedStyle.id === 0 ? configuration.fontFamily : selectedStyle?.fontFamily} />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label={t('font-size')}
                name={`fontSize-text-variables-${selectedStyle?.id}`}
              >
                <InputNumber
                  min={minFontSize}
                  max={maxFontSize}
                  disabled={selectedStyle.id === 0 || selectedStyle?.fromTmp}
                  defaultValue={selectedStyle.id === 0 ? configuration.fontSize : selectedStyle?.fontSize}
                  onChange={(e) => changeHandler().fontSize(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Position du texte"
                name={`position-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={styleRules.fontSize}
              >
                <Select
                  options={[{ value: "left", label: 'Gauche' }, { value: "center", label: 'Centre' }, { value: "right", label: 'Droite' }]}
                  onChange={(e) => changeHandler().position(e)}
                  disabled={selectedStyle?.fromTmp}
                  defaultValue={selectedStyle?.positions?.horizontal}
                ></Select>
              </Form.Item>
              <Form.Item style={{ display: 'inline-block', margin: '0 8px' }}>
                <Form.Item label={t('bold')} style={{ display: 'inline-block', margin: '0 8px' }}>
                  <Checkbox
                    key='bold'
                    style={{ fontWeight: 'bold' }}
                    defaultChecked={selectedStyle?.fontWeight !== ''}
                    onChange={(e) => changeHandler().fontWeight(e.target.checked)}
                    disabled={selectedStyle?.fromTmp}
                  />
                </Form.Item>
                <Form.Item label={t('italic')} style={{ display: 'inline-block', margin: '0 8px' }}>
                  <Checkbox
                    key='italic'
                    style={{ fontStyle: 'italic' }}
                    defaultChecked={selectedStyle?.fontStyle !== ''}
                    onChange={(e) => changeHandler().fontStyle(e.target.checked)}
                    disabled={selectedStyle?.fromTmp}
                  />
                </Form.Item>
                <Form.Item label={t('underline')} style={{ display: 'inline-block', margin: '0 8px' }}>
                  <Checkbox
                    key='underline'
                    style={{ textDecoration: 'underline' }}
                    defaultChecked={selectedStyle?.textDecoration !== ''}
                    onChange={(e) => changeHandler().textDecoration(e.target.checked)}
                    disabled={selectedStyle?.fromTmp}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  label="Position de l'image dans le text"
                  name={`imagePosition-${selectedStyle?.id}`}
                  style={{ display: 'inline-block', margin: '0 8px' }}
                >
                  <Select
                    options={[{ value: "start", label: <i class="fa-duotone fa-solid fa-objects-align-top"></i> },
                    { value: "center", label: <i class="fa-duotone fa-solid fa-objects-align-center-vertical"></i> },
                    { value: "end", label: <i class="fa-duotone fa-solid fa-objects-align-bottom"></i> }]}
                    onChange={(e) => changeHandler().imagePositionInText(e)}
                    defaultValue={selectedStyle?.imagePosition}
                  ></Select>
                </Form.Item>
              </Form.Item>
            </Form.Item>
          </fieldset>
        </div>
      </div>
    </div>
  );
};
export default TextTabs;
