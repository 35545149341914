import _ from 'lodash'
import { memo, useCallback, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useStore } from '../../store/store.js'
import Box from './Box.jsx'
import { ItemTypes, MASKS_TYPE, RESIZING_BORDER, viewerTypes } from '../../constants/constants.js'

const getStyles = (left, top, rotation, isDragging, drawMode, fromViewer, isSelected) => {
  if (fromViewer === viewerTypes.VARIABLES) {
    return isSelected ? { outline: '2px solid ' + RESIZING_BORDER } : {}
  }
  if (!drawMode)
    return {
    }
  const positionTransform = `translate3d(${left}px, ${top}px, 0)`;
  const rotationTransform = _.isNumber(rotation) ? ` rotate(${rotation}deg)` : '';
  const transform = positionTransform + rotationTransform;
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    transition: 'opacity 0.1s ease-in-out',
    cursor: !fromViewer ? 'grab' : 'inherit',
  }
}

const selector =
  (id) =>
    ({
      selectedBoxId,
      slider,
      boxes,
      masks,
      containers,
      currentProposal,

    }) => {
      const allBoxes = [
        ...boxes,
        ...masks.map((m) => m.boxes).flat()
      ]
      const box = allBoxes.find((b) => b.id === id);
      const container = containers.find(container => container.columnsIds.includes(box.columnId))
      const maskType = masks.find((m) => m.id === MASKS_TYPE.HEADER.id)?.editableMask;
      const maskTypeEdiatble = typeof maskType !== 'boolean' ? true : maskType;

      return {
        isSelected: selectedBoxId === id,
        isSlided: slider.boxes.includes(id),
        box: box,
        container: container,
        currentProposal,
        maskTypeEdiatble: maskTypeEdiatble,
        boxesInGroup: box.type === ItemTypes.GROUP_BOXES ? allBoxes.filter((b) => box.boxIds.includes(b.id)) : undefined
      }
    };

export const Dragger = memo(function Dragger({ id, drawMode, fromViewer }) {
  const {
    box,
    container,
    currentProposal,
    maskTypeEdiatble,
    isSelected,
    boxesInGroup
  } = useStore(selector(id));
  const [{ isDragging }, drag, preview] = useDrag(
    () => {
      return {
        type: box.type,
        item: {
          id,
          left: box.left,
          top: box.top,
          rotation: box.rotation,
          parentId: box.columnId || box.maskId || box.pageId,
          boxIds: box.boxIds
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging() || (monitor.getItem()?.boxIds?.includes(id) && !monitor.getItem()?.copied),
        })
      }
    },
    [id, box.left, box.top],
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dragRule = useCallback(() => {
    if (drawMode && !fromViewer) {
      if (currentProposal.id === null && container?.from_linked_section) return undefined;
      if (currentProposal.id === null) return drag
      if (currentProposal.id !== null) {
        if ((box.maskId === "header" || box.maskId === "footer") && !maskTypeEdiatble && box.type !== "img") return undefined
        if ((typeof container?.editable !== 'boolean' || container?.editable)) return drag
        if (typeof container?.editable === 'boolean' && !container?.editable && box.type === "img") return drag
      }
    } else {
      return undefined
    }
  }, [container, drawMode, drag, box, maskTypeEdiatble, fromViewer])

  return (
    <div
      ref={dragRule()}
      id={`box-container-${box?.id ? box.id : 'new'}`}
      style={{
        ...getStyles(
          box.left,
          box.top,
          box.rotation,
          isDragging,
          drawMode,
          fromViewer,
          isSelected),
        zIndex: boxesInGroup ? (Math.max(...boxesInGroup.map((b) => ~~b.zIndex)) + ~~box?.zIndex) : box?.zIndex,
      }}
    >
      <Box id={id} drawMode={drawMode} fromViewer={fromViewer} />
    </div>
  )
})
