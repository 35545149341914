import React from 'react'
import { useStore } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { fetchTableData } from '../../utils/table';
import ButtonTableNavbar from './PopMenu';
import SelectTableRows from './SelectTableRows';
import { CaretDownOutlined } from '@ant-design/icons';
import NavbarSelector from '../Layout/NavbarSelector';
import TableCascader from './TableCascader';
import VariableSwitcher from '../common/VariableSwitcher';
import HideInFormSwitcher from '../common/HideInFormSwitcher';
import { Separator } from '../Layout/Navbar';

const selector = (box) => (state) => {
  return {
    currentDocument: state.currentDocument,
    updateBox: state.updateBox,
    isAdmin: state.user?.isAdmin,
    fetchTable: state.fetchTable,
    tableStyles: state.configuration.tableStyles,
  };
};


const NavbarTable = ({ selectedBox, controlRowClicked, setControlRowClicked, tableStyle }) => {
  const boxId = selectedBox?.id;
  const {
    currentDocument,
    updateBox,
    fetchTable,
    isAdmin,
    tableStyles,
  } = useStore(selector(selectedBox));
  const { t } = useTranslation();

  const handleNavbarClick = ({ action, value }, key) => {
    switch (action) {
      case 'alignment':
        updateBox(boxId, (box) => {
          const targetedElement = box.content.columns.find((c) => c.key === key);
          if (targetedElement) {
            targetedElement.textAlign = value;
          }
        });
        break;
      default:
        break;
    }
  };

  const handleChangeTable = (tableName, proposal) => {
    if (isAdmin && currentDocument.type === 'template') {
      updateBox(selectedBox.id, (boxToUpdate) => {
        boxToUpdate.tableName = tableName;
        boxToUpdate.content.columns = [];
        boxToUpdate.content.data = [];
        boxToUpdate.proposal = proposal;
      });
    } else {
      fetchTableData({
        fetchStoreFunc: fetchTable,
        updateBoxFunc: updateBox,
        box: {
          ...selectedBox,
          tableName,
          proposal
        }
      });
    }
  };


  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <HideInFormSwitcher boxId={boxId} />
      <VariableSwitcher boxId={boxId} />
      <ButtonTableNavbar
        onClick={handleNavbarClick}
        type={controlRowClicked.parentRowType}
        itemKey={controlRowClicked.parentRowId}
      />
      <SelectTableRows />
      <NavbarSelector
        tooltipContent={t('table-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(boxId, (box) => {
            box.tableStyleId = tableStyles.find((ts) => ts.name === e).id;
          });
        }}
        selectedValue={tableStyle.name}
        options={tableStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      <TableCascader
        onChange={handleChangeTable}
        selectedBoxType={selectedBox?.type}
        selectedBox={selectedBox}
      />
      <Separator />
    </div>
  );
};

export default NavbarTable
