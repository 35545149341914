import { produce } from 'immer';
import { getDocumentFromParams } from '../utils/browser';
import {
  defaultConfiguration,
  defaultItemValues,
  defaultMasks,
} from '../constants/constants';

export const browserActions = (set, get) => ({
  setCurrentDocument: (params) => {
    const doc = getDocumentFromParams(params);
    set(
      produce((state) => {
        state.currentDocument = doc;
        state.masks = defaultMasks;
        state.configuration = defaultConfiguration;
        state.summaryConfiguration =
          defaultItemValues.summary.content.configuration;
        state.gridConfiguration = defaultItemValues;
        state.boxes = [];
        state.variables = {};
        state.containers = [];
        state.columns = [];
        state.variableGroups = [];
        state.copy = null;
        state.variableOrderArray = [];
        state.triggers = {};
        state.sectionsToActivate = [];
        state.hasOpportunityOption = false;
        state.hasCostingActivated = false;
        state.zoneNames = [];
        state.zoneIcon = 'fa-kit fa-solid-bars-progress-gear';
        state.itemsChangedByTriggers = [];
        state.tmp = undefined;
      }),
      false,
      `Set current document => ${doc.type} n°${doc.id}`
    );
  },
});
