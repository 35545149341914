import _ from 'lodash';
import { Resizable } from 're-resizable';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AUTO_HEIGHT_BOXES, defaultImgAspectRatio, defaultZoom, dynamicZoom, ItemTypes, MASKS_TYPE, RESIZING_BORDER, roundButtonWidth } from '../../constants/constants';
import { useStore } from '../../store/store';
import { getRatioMove, getRotatedBoxPositionAfterResize, isAImgBox, isATableBox, reinitializeBoxWidthAndHeight, rotatedBoxDelta } from '../../utils/boxes';
import { resizeHandlersPresence, resizeHandlersStyles } from '../../utils/styles';
import { LOCK_PROPORTIONS_SHAPES, LOCK_PROPORTIONS_TYPES, NO_HEIGHT_TYPES, shapeRatios } from '../Shape/shapeUtils';
import Box from './Box';
import { gridHeight, gridWidth } from '../../constants/gridConfig';
import { maxContainerHeight } from '../../utils/containers';

const getStyles = (left, top, rotation) => {
  const positionTransform = `translate3d(${left}px, ${top}px, 0)`;
  const rotationTransform = _.isNumber(rotation) ? ` rotate(${rotation}deg)` : '';
  const transform = positionTransform + rotationTransform;
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
  }
}

const absoluteStyle = {
  position: 'absolute',
  left: 0,
  top: 0
}

const selector =
  (id) =>
    ({
      selectedBoxId,
      boxes,
      masks,
      updateBox,
      configuration
    }) => {
      const allBoxes = [
        ...boxes,
        ...masks.map((m) => m.boxes).flat()
      ]
      const box = allBoxes.find((b) => b.id === id);
      return {
        isSelected: selectedBoxId === id,
        box,
        updateBox,
        configuration,
        boxesInGroup: box.type === ItemTypes.GROUP_BOXES ? allBoxes.filter((b) => box.boxIds.includes(b.id)) : undefined
      }
    }

const heightFromContent = (box) => {
  const autoHeight = (box.height && box.height === 'auto') || AUTO_HEIGHT_BOXES.includes(box.type)
  return !box.height || autoHeight
}


const Resizer = ({ id, drawMode, columnWidth, fromViewer }) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const {
    isSelected,
    updateBox,
    box,
    configuration,
    boxesInGroup
  } = useStore(selector(id));
  const { left, top, type, height, width, rotation, ratio, content, zIndex: boxZIndex } = box;
  const zIndex = boxesInGroup ? (Math.max(...boxesInGroup.map((b) => ~~b.zIndex)) + ~~boxZIndex) : boxZIndex
  const resizableRef = useRef(null);
  const firstRenderImgUpload = useRef(true);
  const [size, setSize] = useState({
    width,
    height: _.isNumber(height) ? height : '100%',
  });
  const initialSize = useRef({
    width,
    height: heightFromContent({ height, type }) ? "100%" : height

  });

  const [position, setPosition] = useState({ left, top });

  useEffect(() => {
    setPosition({ left, top });
  }, [left, top]);

  // Resize Img Box on img load
  useLayoutEffect(() => {
    if (firstRenderImgUpload.current) {
      firstRenderImgUpload.current = false;
      return;
    }
    if (isAImgBox(type) && ratio && resizableRef?.current) {
      resizableRef.current.updateSize({ width, height: 'auto' });
    }
  }, [ratio, type, width]);

  useEffect(() => {
    setSize({
      width,
      height,
    })
  }, [width, height]);

  const handleResizeDefaultColumn = (box, args) => {
    const newBox = reinitializeBoxWidthAndHeight({
      box: {
        ...box,
        width: Math.floor(args[2].clientWidth),
        height: heightFromContent(box) ? 'auto' : Math.floor(args[2].clientHeight),
      },
      columnWidth,
      onResize: true,
      header,
      footer
    })
    const newSize = {
      width: newBox.width,
      height: newBox.height,
    }
    setSize(newSize);
    resizableRef.current.updateSize(newSize);
  }

  const handleResizeDrawingColumn = (box, args) => {
    const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;
    const x = args[0].movementX / zoomFactor, y = args[0].movementY / zoomFactor;
    const resizeMove = rotatedBoxDelta({ rotation: box.rotation, direction: args[1], x, y });
    const ratioMove = getRatioMove(resizeMove, lockAspectRatio);
    const newSize = {
      width: Math.max(size.width + ratioMove.width, gridWidth),
      height: heightFromContent(box) ? '100%' : Math.max((size.height + ratioMove.height), gridHeight),
    }
    setSize(newSize);
    if (rotation && rotation !== 0) {
      const realSizeHeight = heightFromContent(box) ? resizableRef.current.resizable.clientHeight : size.height;
      const newPosition = getRotatedBoxPositionAfterResize(box, size.width, realSizeHeight, initialSize.height);
      setPosition({
        left: newPosition.left,
        top: newPosition.top
      })
    }
    resizableRef.current.updateSize(size);
  }

  const handleResizeStop = useCallback(() => {
    updateBox(
      id,
      (box) => {
        box.width = size.width;
        box.top = position.top;
        box.left = position.left;
        if (box.type !== 'line') {
          if (NO_HEIGHT_TYPES.includes(box.type)) {
            box.height = "auto"
          } else {
            box.height = size.height;
          }
        }
      },
      { actionNameSuffix: 'from_handle_resize' }
    );
  }, [id, position.left, position.top, size.height, size.width, updateBox]);

  const lockAspectRatio = useMemo(() => LOCK_PROPORTIONS_TYPES.includes(type)
    ? ratio
      ? ratio
      : defaultImgAspectRatio
    : LOCK_PROPORTIONS_SHAPES.includes(content?.shape)
      ? 1 / shapeRatios(content?.shape)
      : false, [content?.shape, ratio, type]);

  const allowHandlers = useMemo(() => {
    const isLine = type === 'line';
    const isGroupBox = type === ItemTypes.GROUP_BOXES;
    const isArrow = type === 'shape' && content.shape === 'arrow';
    const allowBottom =
      !NO_HEIGHT_TYPES.includes(type)
      && !LOCK_PROPORTIONS_TYPES.includes(type)
      && !LOCK_PROPORTIONS_SHAPES.includes(content?.shape)
      && !isLine
      && !isArrow
      && !isGroupBox;
    const allowRight = !isGroupBox
    return { right: allowRight, bottom: allowBottom }
  }, [content?.shape, type]);

  const commonResizerProps = useMemo(() => {
    const resizeHandlers = resizeHandlersPresence(allowHandlers);
    const handlersStyle = {
      bottom: resizeHandlers.bottom ? resizeHandlersStyles.bottom : null,
      right: resizeHandlers.right ? resizeHandlersStyles.right : null,
    };

    return {
      ref: resizableRef,
      id: 'resizable-box',
      onResizeStop: (...args) => handleResizeStop(),
      lockAspectRatio,
      enable: isSelected && !isATableBox(type) &&
        type !== ItemTypes.LINE_BREAK &&
        !box?.from_linked_section &&
        type !== ItemTypes.LINE_BREAK && type !== ItemTypes.SPACE &&
        (drawMode || type !== ItemTypes.CHECKBOXS_VARIABLE) ? resizeHandlers : false,
      handleStyles: isSelected && !isATableBox(type) ? handlersStyle : null,
    }
  }, [allowHandlers, handleResizeStop, isSelected, lockAspectRatio, type]);

  return (
    <>
      {isATableBox(type) && drawMode && (
        <div
          id={`box-container-${box?.id ? box.id : 'new'}`}
          style={{
            ...getStyles(position.left, position.top, rotation),
            zIndex: zIndex,
          }}
        >
          <Box id={id} drawMode={drawMode} fromViewer={fromViewer} />
          <div
            style={{
              ...absoluteStyle,
              // outline: '2px solid ' + RESIZING_BORDER,
              zIndex: isSelected ? 0 : zIndex,
              width: "100%",
              height: "100%"
            }}
          >
          </div>
        </div>
      )}

      {!drawMode && <Resizable
        onResize={(...args) => { handleResizeDefaultColumn(box, args) }}
        maxWidth={box.type !== ItemTypes.LINE_BREAK && columnWidth}
        minWidth={roundButtonWidth}
        maxHeight={isATableBox(box.type) || box.type === ItemTypes.SUMMARY ? undefined : maxContainerHeight({ header, footer })}
        minHeight={16}
        size={{
          width: size.width,
          height: heightFromContent(box) ? 'auto' : size.height,
        }}
        style={{
          outline: isATableBox(box.type) ? undefined : '2px solid ' + RESIZING_BORDER,
          zIndex: isSelected ? 3 : 0,
        }}
        {...commonResizerProps}
      >
        <Box id={id} width={size.width} height={size.height} drawMode={drawMode} fromViewer={fromViewer} />
      </Resizable>}

      {!isATableBox(type) && drawMode &&
        <div
          id={`box-container-${box?.id ? box.id : 'new'}`}
          style={{
            ...getStyles(position.left, position.top, rotation),
            zIndex: zIndex,
          }}
        >
          <Box id={id} width={size.width} height={size.height} drawMode={drawMode} fromViewer={fromViewer} />
          <Resizable
            onResize={(...args) => { handleResizeDrawingColumn(box, args) }}
            {...commonResizerProps}
            size={{
              width: size.width,
              height: heightFromContent(box) ? '100%' : size.height,
            }}
            style={{
              ...absoluteStyle,
              outline: '2px solid ' + RESIZING_BORDER,
              zIndex: isSelected ? 0 : zIndex,
            }}
          />
        </div>}
    </>
  )
};

export default Resizer;
