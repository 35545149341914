import React, { useLayoutEffect, useRef, useState } from 'react'
import TextStylingComponent from '../TextStylingComponent/TextStylingComponent';
import FloatyStylingWrapper from './FloatyStylingWrapper';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import { useStore } from '../../store/store';
import { useOverlay } from '../../contexts/OverlayContext';

const FloatyTextStyling = ({
  editorState,
  setEditorState,
  defaultFontSize,
  defaultFontFamily,
  defaultColor,
  boxId,
  updateBox,
  hasAlignement,
  textStyle,
  hasForm,
  hasList,
}) => {
  const [menuPosition, setMenuPosition] = useState({ top: null, left: null });
  const landscape = useStore(({ landscape }) => landscape)
  const ref = useRef()
  const { overlayProps } = useOverlay();

  const shownStatus = overlayProps.left.shownStatus

  useLayoutEffect(() => {
    const selection = window.getSelection && window.getSelection();
    if (selection && selection.rangeCount > 0 && (
      editorState.getSelection().getStartOffset() !== editorState.getSelection().getEndOffset() ||
      editorState.getSelection().getStartKey() !== editorState.getSelection().getEndKey()
    )) {
      const selectionRange = selection.getRangeAt(0);
      const { top, left } = selectionRange.getBoundingClientRect()
      if (top !== 0 && left !== 0)
        setMenuPosition({ top: top - 50, left })
    } else {
      setMenuPosition({ top: null, left: null })
    }
  }, [editorState]);

  useLayoutEffect(() => {
    if (menuPosition.top !== null && menuPosition.left !== null) {
      if (ref.current) {
        const boundary = shownStatus === 'summary' ? (window.innerWidth / 2 + (landscape ? pageHeight : pageWidth) / 2) : window.innerWidth
        if (menuPosition.left + ref.current.clientWidth > boundary) {
          setMenuPosition({ top: menuPosition.top, left: boundary - ref.current.clientWidth })
        }
      }
    }
  }, [shownStatus, menuPosition, landscape])

  if (menuPosition.top === null || menuPosition.left === null) return null

  return (
    <FloatyStylingWrapper menuPosition={menuPosition} ref={ref}>
      <TextStylingComponent
        editorState={editorState}
        setEditorState={setEditorState}
        defaultFontSize={defaultFontSize}
        defaultFontFamily={defaultFontFamily}
        defaultColor={defaultColor}
        updateBoxOnStylingChange={() => null}
        hasOptionsAfter={false}
        boxId={boxId}
        updateBox={updateBox}
        hasAlignement={hasAlignement}
        hasForm={hasForm}
        textStyle={textStyle}
        hasList={hasList}
      />
    </FloatyStylingWrapper>
  )
}

export default FloatyTextStyling
